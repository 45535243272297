import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../assets/Layout/MainLayout";
import copy from "../../../assets/Images/icons/faLink.svg";
import waste from "../../../assets/Images/image/Waste.png";
import { CouponWrapper } from "./style/Coupon";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import CopyToClipboard from "react-copy-to-clipboard";
import Popup from "./components/Popup";
import moment from "moment";
import { couponTypeName } from "../../../config/constant";
import { formatCurrency } from "../../../core/utils";

const EditCoupon = () => {
  const location = useLocation();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const couponType = location.state?.couponType;
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  const [addPromo, _addPromo] = useState(false);
  const [loading, _loading] = useState(false);

  const [type, _type] = useState("percentage");
  const [durationVal, _durationVal] = useState("");
  const [dateRange, _dateRange] = useState(false);
  const [noRange, _noRange] = useState(false);
  const [dateVal, _dateVal] = useState("");
  const [redeemVal, _redeemVal] = useState("");
  const [validity, _validity] = useState("");
  const [profile, _profile] = useState("");

  //Plan
  const [plans, _plans] = useState([]);
  const [planID, _planID] = useState("");
  const [planDetails, _planDetails] = useState("");

  //Promo Codes
  const [popup, _popup] = useState(false);
  const [loaded, _loaded] = useState(false);
  const [plansLoaded, _plansLoaded] = useState(false);
  const [promoCodes, _promoCodes] = useState([]);

  const [publicAvailabilty, _publicAvailabilty] = useState(false);

  // const [promoChecked,_promoChecked] = useState(true)

  const { couponId } = useParams();

  const today = new Date();
  var currDate = new Date().toISOString().slice(0, 16);

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    if (plansLoaded === true) {
      getCoupon();
    }
  }, [plansLoaded]);

  const getCoupon = () => {
    API.get(APP_URLS.VIEW_COUPON + `/${couponId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setValues(resp.data.item);
          _promoCodes(resp.data.item.promo_codes);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.data !== undefined) {
          if (resp.data.data.message) {
            error_message = resp.data.data.message;
          } else {
            {
              Object.keys(resp.data.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          }
        } else if (resp?.data?.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp?.data?.error !== undefined) {
          error_message = resp?.data.error;
        } else {
          error_message = resp?.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getPlans = () => {
    if (couponType === couponTypeName.SUBSCRIPTIONS) {
      API.get(APP_URLS.GET_PLANS + "?plan_type=year&is_deleted=true")
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            _plans(resp.data.plans);
            _plansLoaded(true);
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            if (resp.data.data.message) {
              error_message = resp.data.data.message;
            } else {
              {
                Object.keys(resp.data.data).map(
                  (error, index) => (error_message = resp.data.data[error][0])
                );
              }
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      API.get(`${APP_URLS.GET_PLANS}/credit_plans`)
        .then((res) => {
          const resp = res.data;
          if (resp.success === true) {
            _plans(resp.data.items);
            _plansLoaded(true);
          } else {
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          const resp = error.response;
          let error_message = "";
          if (resp.data.data !== undefined && resp.data.data !== null) {
            if (resp.data.data.message) {
              error_message = resp.data.data.message;
            } else {
              {
                Object.keys(resp.data.data).map(
                  (error, index) => (error_message = resp.data.data[error][0])
                );
              }
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
            error_message = resp.data.message;
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const setValues = (data) => {
    _validity(data.valid);
    formik.setFieldValue("name", data.name);
    _publicAvailabilty(data?.public_availability ?? false);
    if (data.amount_off !== null) {
      _type("amount");
      formik.setFieldValue("type", "amount");
      document.getElementById("amountDiscount").checked = true;
      formik.setFieldValue("percentageOff", data.amount_off / 100);
    }
    if (data.percent_off !== null) {
      _type("percentage");
      formik.setFieldValue("type", "percentage");
      document.getElementById("percentageDiscount").checked = true;
      formik.setFieldValue("percentageOff", data.percent_off);
    }
    formik.setFieldValue("duration", data.duration);
    _durationVal(data.duration);
    formik.setFieldValue("no_of_months", data.duration_in_months);
    if (data.applies_to !== null && data.applies_to.length !== 0) {
      setChecked(true);
      // Check if the products array in applies_to matches the products array in data
      const filteredPlans = plans.filter((plan) => {
        return data.applies_to.products.some((product) =>
          product.includes(plan.stripe_product_id)
        );
      });
      // _planID(data.applies_to.products[0])
      _planDetails(filteredPlans);
    }
    formik.setFieldValue("duration", data.duration);
    formik.setFieldValue("type_profile", data.profile_type);
    _profile(data.profile_type);
    if (data.max_redemptions !== null) {
      _noRange(true);
      formik.setFieldValue("limitTimes", true);
      document.getElementById("limitTimes").checked = true;
    }
    if (data.redeem_by !== null) {
      _dateRange(true);
      formik.setFieldValue("limitRange", true);
      document.getElementById("limitRange").checked = true;
    }
    if (data.max_redemptions) {
      formik.setFieldValue("timesRange", data.max_redemptions);
      _redeemVal(data.max_redemptions);
    }
    if (data.redeem_by) {
      formik.setFieldValue(
        "dateRange",
        moment(data.redeem_by).tz("Europe/Amsterdam").format("yyyy-MM-DDTHH:mm")
      );
      _dateVal(
        moment(data.redeem_by).tz("Europe/Amsterdam").format("yyyy-MM-DDTHH:mm")
      );
    }
  };

  // const handleProducts = (val) =>{
  //   setChecked(!checked)
  //   if(val===false){
  //     _planDetails('')
  //     _planID('')
  //   }
  // }

  const handleSwitch = (id) => {
    let newData = [...promoCodes];
    let val = newData.find((o) => o.id === id);
    if (val.active === 1) {
      val.active = 0;
    } else {
      val.active = 1;
    }
    _promoCodes(newData);
  };

  const disableCoupon = (id, val) => {
    let data = {};
    data["active"] = val;
    API.post(APP_URLS.UPDATE_PROMO + `/${id}?_method=PUT`, data)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(
            `Promo Code has been ${
              val === 0 ? "archived" : "unarchived"
            } successfully.`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          getCoupon();
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          if (resp.data.data.message) {
            error_message = resp.data.data.message;
          } else {
            {
              Object.keys(resp.data.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      percentageOff: "",
      type_profile: "",
      duration: "",
      no_of_months: "",
      limitRange: "",
      dateRange: "",
      limitTimes: "",
      timesRange: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Coupon Name is required"),
      type: Yup.string().required("Select coupon discount type"),
      type_profile: Yup.string().required("Select type of profile"),
      percentageOff: Yup.number()
        .required("Discount amount/percentage is required")
        .when("type", {
          is: "amount",
          then: Yup.number().integer("Decimal Values aren't allowed"),
        }),
      duration: Yup.string().required("Coupon Duration is required"),
      no_of_months: Yup.string()
        .ensure()
        .when("duration", {
          is: "repeating",
          then: Yup.string().required("Number of months is required"),
        }),
      dateRange: Yup.string()
        .ensure()
        .when("limitRange", {
          is: true,
          then: Yup.string().required("Redeem Date is required"),
        }),
      timesRange: Yup.string()
        .ensure()
        .when("limitTimes", {
          is: true,
          then: Yup.string().required("Count is required"),
        }),
    }),
    onSubmit: (values) => {
      const data = {};
      data["name"] = values.name;
      data["duration"] = values.duration;
      data["profile_type"] = values.type_profile;
      if (checked) {
        let newData = {};
        newData["products"] = [planID];
        data["applies_to"] = newData;
      }
      if (values.type === "percentage") {
        data["percent_off"] = parseFloat(values.percentageOff).toFixed(2);
      }
      if (values.type === "amount") {
        data["amount_off"] = values.percentageOff;
      }
      if (values.duration === "repeating") {
        data["duration_in_months"] = values.no_of_months;
      }
      if (values.limitRange === true) {
        data["redeem_by"] = values.dateRange;
      }
      if (values.limitTimes === true) {
        data["max_redemptions"] = values.timesRange;
      }
      if (checked && planID === "") {
        toast.error("Please choose a product plan", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        if (!loading) {
        }
      }
    },
  });

  const handleVisibility = (value) => {
    if (loading) {
      return;
    }
    _loading(true);
    API.post(`${APP_URLS.VIEW_COUPON}/${couponId}?_method=patch`, {
      public_availability: +value,
    })
      .then((res) => {
        _loading(false);
        const resp = res.data;
        if (resp.success === true) {
          _publicAvailabilty(value);
          toast.success(
            value
              ? "The promo is now publicly visible to everyone."
              : "The promo code is not publicly visible but remains usable if manually entered by the user.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          if (resp.data.data.message) {
            error_message = resp.data.data.message;
          } else {
            {
              Object.keys(resp.data.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <CouponWrapper>
        {popup === true ? (
          <Popup
            _popup={_popup}
            couponId={couponId}
            getCoupon={getCoupon}
            redeemVal={redeemVal}
            dateVal={dateVal}
            currDate={currDate}
          />
        ) : (
          ""
        )}
        <div className="mt-2">
          <div
            className="backContainer ms-4"
            onClick={() =>
              navigate(
                "/coupons?" +
                  (query.get("keyword") !== null
                    ? "keyword=" + query.get("keyword") + "&"
                    : "") +
                  (query.get("trashed") !== null
                    ? "trashed=" + query.get("trashed") + "&"
                    : "") +
                  (query.get("profile_type") !== null
                    ? "profile_type=" + query.get("profile_type") + "&"
                    : "") +
                  (query.get("duration") !== null
                    ? "duration=" + query.get("duration") + "&"
                    : "") +
                  (query.get("per_page") !== null
                    ? "per_page=" + query.get("per_page") + "&"
                    : "") +
                  (query.get("page") !== null
                    ? "page=" + query.get("page")
                    : "")
              )
            }
          >
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <form className="auctionMainContainer" onSubmit={formik.handleSubmit}>
          <div className="headerContainer d-flex justify-content-center">
            <h1>EDIT COUPON</h1>
          </div>
          <div className="actionContainer d-flex justify-content-end mb-4">
            {/* <button className="btn" type="submit">Save</button> */}
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-5 pt-2 mb-3">
                <div className="row">
                  <div className="col-md-12 form-group">
                    <label htmlFor="name" className="form-label">
                      <b>Name</b>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="First purchase discount"
                      className="form-control disabled"
                      disabled
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="error">{formik.errors.name}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label htmlFor="seller" className="label my-3">
                        <b>Type</b>
                      </label>
                      <div className="col-md-12 mb-2 form-group">
                        <input
                          type="radio"
                          id="percentageDiscount"
                          name="type"
                          value="percentageDiscount"
                          className="ms-1 disabled"
                          disabled
                          onChange={() => {
                            _type("percentage");
                            formik.setFieldValue("type", "percentage");
                          }}
                        />
                        <label htmlFor="html" className="form-label ms-3">
                          Percentage discount
                        </label>
                      </div>
                      <div className="col-md-12 form-group">
                        <input
                          type="radio"
                          id="amountDiscount"
                          name="type"
                          value="amountDiscount"
                          className="ms-1 disabled"
                          disabled
                          onChange={() => {
                            _type("amount");
                            formik.setFieldValue("type", "amount");
                          }}
                        />
                        <label htmlFor="css" className="form-label ms-3">
                          Amount discount
                        </label>
                      </div>
                      {formik.touched.type && formik.errors.type ? (
                        <div className="error">{formik.errors.type}</div>
                      ) : null}
                    </div>
                  </div>
                  {couponType === couponTypeName.CREDITS ? (
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                      <div className="row">
                        <div className="switchBox d-flex">
                          <Switch
                            checked={publicAvailabilty}
                            className="react-switch align-self-center"
                            onChange={handleVisibility}
                            disabled={!validity}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            offColor="#D9D9D9"
                            onColor="#7589EC"
                          />
                          <label className="ps-3">Show publically</label>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row mt-3">
                    <div className="col-md-6 form-group">
                      <div className="formInput">
                        <label htmlFor="percentage" className="form-label">
                          <b>
                            {type === "percentage"
                              ? "Percentage Off"
                              : "Discount amount"}
                          </b>
                        </label>
                        <div className="input-group">
                          <input
                            type="number"
                            id="percentage"
                            name="percentageOff"
                            className="form-control disabled"
                            disabled
                            value={formik.values.percentageOff}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <span
                            className="input-group-text"
                            id="off-percentage"
                          >
                            {type === "percentage" ? "%" : "€"}
                          </span>
                        </div>
                        {formik.touched.percentageOff &&
                        formik.errors.percentageOff ? (
                          <div className="error">
                            {formik.errors.percentageOff}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 form-group">
                      <div className="formInput">
                        <label htmlFor="duration" className="form-label">
                          <b>Duration</b>{" "}
                        </label>
                        <select
                          name="duration"
                          id="duration"
                          value={formik.values.duration}
                          onChange={(e) => {
                            formik.handleChange(e);
                            _durationVal(e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          disabled
                          className="form-control disabled"
                        >
                          <option hidden>Select</option>
                          <option value="forever">Forever</option>
                          <option value="once">Once</option>
                          <option value="repeating">Repeating</option>
                        </select>
                      </div>
                      {formik.touched.duration && formik.errors.duration ? (
                        <div className="error">{formik.errors.duration}</div>
                      ) : null}
                    </div>
                    {durationVal === "repeating" ? (
                      <div className="col-md-12 form-group mt-3">
                        <label htmlFor="no_of_months" className="form-label">
                          <b>Number of months</b>{" "}
                        </label>
                        <div className="input-group">
                          <input
                            type="number"
                            id="no_of_months"
                            name="no_of_months"
                            className="form-control disabled"
                            disabled
                            value={formik.values.no_of_months}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <span
                            className="input-group-text"
                            id="off-percentage"
                          >
                            Months
                          </span>
                        </div>
                        {formik.touched.no_of_months &&
                        formik.errors.no_of_months ? (
                          <div className="error">
                            {formik.errors.no_of_months}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {couponType === couponTypeName.SUBSCRIPTIONS ? (
                    <div className="col-md-12 form-group mt-3">
                      <label htmlFor="type_profile" className="form-label">
                        <b>Type of Profile</b>{" "}
                      </label>
                      <select
                        name="type_profile"
                        id="type_profile"
                        value={formik.values.type_profile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled
                        className="form-control disabled"
                      >
                        <option hidden>Select</option>
                        <option value="1">Admin</option>
                        <option value="3">Nomad</option>
                      </select>
                      {formik.touched.type_profile &&
                      formik.errors.type_profile ? (
                        <div className="error">
                          {formik.errors.type_profile}
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  <div className="col-md-12 mt-3">
                    <div className="row">
                      <label
                        htmlFor="redemptionLimits"
                        className="col-md-12 form-label mt-2"
                      >
                        <b>Redemption limits</b>
                      </label>
                      <div className="col-md-12 form-group my-1 d-flex">
                        <input
                          type="checkbox"
                          className="align-self-center me-3 ms-1 disabled"
                          id="limitRange"
                          name="limitRange"
                          disabled
                          value={formik.values.limitRange}
                          onChange={(e) => {
                            formik.handleChange(e);
                            _dateRange(!dateRange);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="vehicle1">
                          {" "}
                          Limit the date range when customers can redeem this
                          coupon
                        </label>
                      </div>
                      {dateRange ? (
                        <>
                          <div className="col-md-12 form-group my-1 d-flex align-items-center">
                            <div className="promoInput ">
                              <span className="fieldTitle ms-2">
                                {" "}
                                Redeem By
                              </span>
                              <input
                                type="datetime-local"
                                className="ms-2 fieldBox border-left border-right disabled"
                                id="dateRange"
                                name="dateRange"
                                disabled
                                min={currDate}
                                value={formik.values.dateRange}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                          {formik.touched.dateRange &&
                          formik.errors.dateRange ? (
                            <div className="error">
                              {formik.errors.dateRange}
                            </div>
                          ) : null}
                        </>
                      ) : (
                        ""
                      )}
                      <div className="col-md-12 form-group my-1 d-flex">
                        <input
                          type="checkbox"
                          className="align-self-center me-3 ms-1 disabled"
                          id="limitTimes"
                          name="limitTimes"
                          disabled
                          value={formik.values.limitTimes}
                          onChange={(e) => {
                            formik.handleChange(e);
                            _noRange(!noRange);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="limitTimes">
                          {" "}
                          Limit the total number of times this coupon can be
                          redeemed
                        </label>
                      </div>
                      {noRange ? (
                        <>
                          <div className="col-md-12 form-group my-1 d-flex align-items-center">
                            <div className="promoInput">
                              <input
                                type="number"
                                className="fieldBox border-right disabled"
                                id="timesRange"
                                name="timesRange"
                                disabled
                                placeholder="1"
                                value={formik.values.timesRange}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <span className="fieldTitle mx-2">times</span>
                            </div>
                          </div>
                          {formik.touched.timesRange &&
                          formik.errors.timesRange ? (
                            <div className="error">
                              {formik.errors.timesRange}
                            </div>
                          ) : null}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 pt-2 mb-5">
                <div className="col-md-6 form-group mb-4">
                  <div className="d-flex">
                    <span className="form-label">
                      <b>Coupon Validity</b>{" "}
                    </span>
                    <span
                      className={`ms-4 status ${
                        validity === 1 ? "status-active" : "status-end"
                      }`}
                      title={validity === 1 ? "Valid" : "Invalid"}
                    ></span>
                  </div>
                </div>
                <div className="switchBox d-flex">
                  {/* <Switch
                    onChange={(e)=>handleProducts(e)}
                    checked={checked}
                    disabled
                    className="react-switch align-self-center disabled"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor="#D9D9D9"
                    onColor="#7589EC"
                  /> */}
                  <label className="fw-bold">Apply to specific products</label>
                </div>
                {checked ? (
                  <div className="table-wrapper">
                    <table className="custom-table">
                      <thead>
                        <tr>
                          <th className="center-text">Select</th>
                          <th className="center-text">Plan Name</th>
                          <th className="center-text">Price ID</th>
                          <th className="center-text">Amount</th>
                        </tr>
                      </thead>
                      <tbody aria-disabled>
                        {planDetails?.length > 0 ? (
                          planDetails.map((item, index) => (
                            <tr key={item.id} className="selected">
                              <td className="center-text">
                                <input
                                  type="checkbox"
                                  checked={true}
                                  disabled
                                />
                              </td>
                              <td className="center-text">{item.name}</td>
                              <td className="center-text">
                                {item.prices[0].id}
                              </td>
                              <td className="center-text">
                                {formatCurrency(item.prices[0].unit_amount) +
                                  `${
                                    couponType === couponTypeName.SUBSCRIPTIONS
                                      ? " / year"
                                      : ""
                                  }`}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="norecord">
                              No plan found..
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="features">
                <h5>Promo Codes</h5>
                {validity === 1 && profile === 1 ? (
                  <button
                    className="btn save-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      _popup(!popup);
                    }}
                  >
                    + Add Promo
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="tableContainer">
                <table className="auctionTable">
                  <thead className="tableHead">
                    <tr className="table-row">
                      <th className="tableHead-1">Promotion Code</th>
                      <th className="tableHead-2">API ID</th>
                      <th className="tableHead-3">Redemptions</th>
                      <th className="tableHead-5">Expires At</th>
                      <th className="tableHead-9">Created At</th>
                      <th className="tableHead-9">Status</th>
                    </tr>
                  </thead>
                  {loaded ? (
                    <div className="d-flex justify-content-center three-dot">
                      <ThreeDots
                        height="150"
                        width="150"
                        radius="9"
                        color="#637df4"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <tbody>
                      {promoCodes.length > 0 ? (
                        promoCodes.map((item, index) => {
                          return (
                            <tr className="table-row" key={index}>
                              <td className="tableHead-1">{item.code}</td>
                              <td className="tableHead-2">
                                <div className="my-2 d-flex justify-content-center">
                                  <span className="copyText">
                                    {item.stripe_promo_code_id}
                                  </span>
                                  <CopyToClipboard
                                    text={item.stripe_promo_code_id}
                                  >
                                    <img
                                      src={copy}
                                      alt=""
                                      onClick={() => toast("Text Copied")}
                                    />
                                  </CopyToClipboard>
                                </div>
                              </td>
                              <td className="tableHead-3">
                                {item.time_redeemed !== null
                                  ? item.time_redeemed
                                  : "-"}
                                /
                                {item.max_redemptions !== null
                                  ? item.max_redemptions
                                  : "-"}
                              </td>
                              <td className="tableHead-5">
                                {item.expires_at !== null
                                  ? moment(item.expires_at)
                                      .tz("Europe/Amsterdam")
                                      .format("DD-MM-YYYY HH:mm")
                                  : "-"}
                              </td>
                              <td className="tableHead-9">
                                {item.created_at !== null
                                  ? moment(item.created_at)
                                      .tz("Europe/Amsterdam")
                                      .format("DD-MM-YYYY HH:mm")
                                  : "-"}
                              </td>
                              <td className="tableHead-9">
                                {item.deleted_at === null ? (
                                  <Switch
                                    onChange={() => {
                                      handleSwitch(item.id);
                                      disableCoupon(item.id, item.active);
                                    }}
                                    checked={item.active === 1 ? true : false}
                                    className={
                                      validity === 0
                                        ? "disabled"
                                        : "" + " react-switch"
                                    }
                                    disabled={validity === 0 ? true : false}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    offColor="#D9D9D9"
                                    onColor="#7589EC"
                                  />
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoRecord />
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </form>
      </CouponWrapper>
    </>
  );
};

export default EditCoupon;
