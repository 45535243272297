import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { deviceTypes, screenNames } from "../../../config/constant";
import { getActiveLanguages } from "../../../core/utils";
import Pagination from "../../../assets/SharedComponents/Pagination";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { Tooltip } from "@mui/material";
import StaticFilter from "../../../assets/SharedComponents/Filters/StaticFilter";
import moment from "moment";
import Sorting from "../../../assets/SharedComponents/Sorting/Sorting";

const Notifications = () => {
  const languages = getActiveLanguages();
  const [notifications, _notifications] = useState([]);

  const [countValue, _countValue] = useState("");

  const [page, setPage] = useState(1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [sort, _sort] = useState("-created_at");
  const [device, _device] = useState("");
  const [language, _language] = useState("");

  const [loaded, _loaded] = useState(false);
  const [filters, _filters] = useState(false);

  const [userAccess] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );

  let customNotifications = userAccess.find(
    (o) => o.slug === "custom_notifications"
  );

  useEffect(() => {
    getNotifications();
  }, [page, countValue, language, device, sort]);

  const getNotifications = () => {
    _loaded(true);
    API.get(
      APP_URLS.CUSTOM_NOTIFICATIONS +
        `?page=${page}&per_page=${countValue}&filter[language_id]=${language}&filter[device_type]=${device}&sort=${sort}`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _notifications(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          if (resp.data.data.message) {
            error_message = resp.data.data.message;
          } else {
            {
              Object.keys(resp.data.data).map(
                (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getName = (key, array) => {
    return array.find((name) => name.value === key)?.name ?? "-";
  };

  const getLanguageName = (key) => {
    return languages.find((language) => language.id === key)?.name ?? "-";
  };

  const handleSearchChange = (count) => {
    _countValue(count);
    if (count !== countValue) {
      if (count !== "") {
        _totalPage(0);
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange("");
    _device("");
    _language("");
    _sort("-created_at");
    if (
      countValue !== "" ||
      language !== "" ||
      device !== "" ||
      sort !== "-created_at"
    ) {
      _totalPage(0);
      setPage(1);
    }
  };

  const handleSort = (sortType) => {
    _sort(sortType);
    setPage(1);
    _totalPage(0);
  };

  return (
    <div className="auctionMainContainer">
      <div className="row mt-4">
        <div className="col-md-4 offset-md-4">
          <h1>Notifications</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-2">
          {customNotifications.permissions.find((o) => o.slug === "add") ? (
            <div className="actionContainer">
              <NavLink className="btn" to="/trigger-new-notification">
                + Trigger New Notification
              </NavLink>
            </div>
          ) : null}
        </div>
        <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
          <StaticFilter
            placeholder="Language"
            data={getActiveLanguages()}
            handleChange={(e) => {
              _language(e.target.value);
              _totalPage(0);
              setPage(1);
            }}
            value={language}
          />
          <StaticFilter
            placeholder="Device"
            data={deviceTypes}
            handleChange={(e) => {
              _device(e.target.value);
              _totalPage(0);
              setPage(1);
            }}
            value={device}
          />
          <RecordCount
            onSearchClick={(count) => handleSearchChange(count)}
            val={countValue}
            id="pageCount"
            filters={filters}
            _filters={_filters}
          />
          <Tooltip title="Clear Filter" placement="bottom">
            <FaFilterIcon
              className="filter-icon"
              role="button"
              onClick={clearFilter}
            />
          </Tooltip>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between info-wrap">
        <div className="justify-content-end mt-1 me-2">
          <span className="text-start">
            Total Records : <b>{total}</b>
          </span>
        </div>
      </div>
      <div className="tableContainer">
        <table className="auctionTable">
          <thead className="tableHead">
            <tr className="table-row">
              <th className="mw-400">Title</th>
              <th className="mw-400">Body</th>
              <th>
                Created At
                <Sorting
                  sortType="created_at"
                  sort={sort}
                  handleSort={handleSort}
                  styles="sortingSvg"
                />
              </th>
              <th>Screen Name</th>
              <th>Language</th>
              <th>Device</th>
            </tr>
          </thead>
          {loaded ? (
            <div className="d-flex justify-content-center three-dot">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <tbody>
              {notifications.length > 0 ? (
                notifications.map((notification, index) => {
                  return (
                    <tr
                      className={
                        (notification.deleted_at ? "deletedRow" : "") +
                        " table-row"
                      }
                      key={index}
                    >
                      <td className="mw-400">{notification.title}</td>
                      <td className="mw-400">{notification.body}</td>
                      <td>
                        {moment(notification.created_at)
                          .tz("Europe/Amsterdam")
                          .format("DD-MM-YYYY HH:mm")}
                      </td>
                      <td>{getName(notification.screen_name, screenNames)}</td>
                      <td>{getLanguageName(notification.language_id)}</td>
                      <td>{getName(notification.device_type, deviceTypes)}</td>
                    </tr>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </tbody>
          )}
        </table>
      </div>
      <div>
        {totalPage > 1 ? (
          <Pagination
            totalPages={totalPage}
            page={page}
            key={totalPage}
            onPageClick={(page) => setPage(page + 1)}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Notifications;
