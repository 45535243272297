// src/context/MaintenanceContext.js
import React, { createContext, useState } from "react";

export const MaintenanceContext = createContext();

export const MaintenanceProvider = ({ children }) => {
  const [isMaintenance, setIsMaintenance] = useState(false);

  return (
    <MaintenanceContext.Provider value={{ isMaintenance, setIsMaintenance }}>
      {children}
    </MaintenanceContext.Provider>
  );
};
