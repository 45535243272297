import { Box, Modal, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import API from "../../../api/axios";
import { toast } from "react-toastify";
import { APP_URLS } from "../../../api/url";
import { Wrapper } from "./style";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import Pagination from "../Pagination";
import { NavLink } from "react-router-dom";
import SearchKey from "../Search/SearchKey";
import StaticFilter from "../Filters/StaticFilter";
import NoRecord from "../Table/NoRecord";
import { FaFilterIcon } from "../../Images/icon";

const profileType = [
  {
    name: "Dealer",
    value: 1,
  },
  {
    name: "Private",
    value: 2,
  },
];

const AdVisitorModal = ({ title, count, id, type }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loaded, _loaded] = useState(false);

  const [history, _history] = useState([]);

  const [totalPage, _totalPage] = useState(0);
  const [total, _total] = useState(0);
  const [page, setPage] = useState(1);

  const [filters, _filters] = useState(false);
  const [searchkeyword, _searchkeyword] = useState("");
  const [phone, _phone] = useState("");
  const [profile, _profile] = useState("");

  useEffect(() => {
    if (!open) {
      _searchkeyword("");
      _phone("");
      _profile("");
      setPage(1);
      return;
    }
    if (profile && !phone && !searchkeyword) {
      return;
    }
    if ((phone || searchkeyword) && !profile) {
      toast.error(
        "For searching using name or phone number please select profile type filter first ",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }
    //fetching hotdeals ads listings
    const getHistory = async () => {
      _loaded(true);
      try {
        const res = await API.get(
          `${APP_URLS.AD_VISITORS}/${id}/${type}?page=${page}${
            profile
              ? `&filter[profile_type]=${profile}&filter[keyword]=${searchkeyword}&filter[phone]=${phone}`
              : ""
          }`
        );
        const { data: resp } = res;
        if (resp.success) {
          _history(resp?.data?.data ?? []);
          _totalPage(resp?.data?.last_page ?? 1);
          _total(resp?.data?.total ?? 0);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        const resp = error.response;
        let errorMessage = resp?.data?.message || "An error occurred";
        if (resp?.data?.errors) {
          errorMessage = Object.values(resp.data.errors).flat().join(", ");
        } else if (resp?.data?.data?.error) {
          errorMessage = resp.data.data.error;
        } else if (resp?.data?.error) {
          errorMessage = resp.data.error;
        }
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } finally {
        _loaded(false);
      }
    };
    getHistory();
  }, [open, page, profile, phone, searchkeyword]);

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (
      (func === _phone && searchValue !== phone) ||
      (func === _searchkeyword && searchValue !== searchkeyword)
    ) {
      if (searchValue !== "") {
        _totalPage(0);
        setPage(1);
      }
    }
  };

  const getName = (resp) => {
    if (resp.user) {
      return resp.user.name;
    }
    if (resp.subsidiary) {
      return resp.subsidiary.name;
    }
    return "-";
  };

  const getUsername = (resp) => {
    if (resp.user) {
      return resp.user.user_name;
    }
    if (resp.subsidiary) {
      return resp.subsidiary.user_name;
    }
    return "-";
  };

  const getPhone = (resp) => {
    if (resp.user) {
      return `${resp.user?.limited_address?.prefix_call ?? "-"} ${
        resp.user?.phone ?? "-"
      }`;
    }
    if (resp.subsidiary) {
      return `${resp.subsidiary?.limited_address?.prefix_call ?? "-"} ${
        resp.subsidiary?.phone ?? "-"
      }`;
    }
    return "-";
  };

  const getPostalCode = (resp) => {
    if (resp.user) {
      return `${resp.user?.limited_address?.postal_code ?? "-"}`;
    }
    if (resp.subsidiary) {
      return `${resp.subsidiary?.limited_address?.postal_code ?? "-"}`;
    }
    return "-";
  };

  const getUserType = (resp) => {
    if (resp.user) {
      return <b className="userType">Private</b>;
    }
    if (resp.subsidiary) {
      return <b className="userType">Pro</b>;
    }
    return "";
  };

  const getDetailsLink = (resp) => {
    if (resp.user) {
      return `/detail-member/${resp.user.id}`;
    }
    if (resp.subsidiary) {
      return `/company-details/${resp.subsidiary.id}`;
    }
    return "";
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_phone, "");
    handleSearchChange(_searchkeyword, "");
    _profile("");
    if (searchkeyword !== "" || phone !== "" || profile !== "") {
      _totalPage(0);
      setPage(1);
    }
  };

  return (
    <>
      <span onClick={handleOpen} className="link">
        {count}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Wrapper>
          <h3>{title}</h3>
          <div className="d-flex align-items-center justify-content-end info-wrap gap-2 mb-2 mt-2">
            <StaticFilter
              placeholder="Profile Type"
              data={profileType}
              handleChange={(e) => {
                _profile(e.target.value);
              }}
              value={profile}
            />
            <SearchKey
              onSearchClick={(search) =>
                handleSearchChange(_searchkeyword, search)
              }
              id="searchDatabyKeyword"
              filters={filters}
              val={searchkeyword}
              _filters={_filters}
              placeholder="Search by Name"
              newClass={!profile ? "disabled" : ""}
              disabled={!profile}
            />
            <SearchKey
              onSearchClick={(search) => handleSearchChange(_phone, search)}
              id="searchDatabyPhone"
              filters={filters}
              val={phone}
              _filters={_filters}
              disabled={!profile}
              newClass={!profile ? "disabled" : ""}
              placeholder="Search by Phone Number"
            />
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon
                className="filter-icon"
                role="button"
                onClick={clearFilter}
              />
            </Tooltip>
          </div>
          <div>
            <span>
              Total Records : <b>{total}</b>
            </span>
          </div>
          <div className="tableContainer">
            <table className="modalTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th>Country</th>
                  <th className="w-150">
                    Full Name/
                    <br />
                    Company Name
                  </th>
                  <th className="w-150">
                    Username/
                    <br />
                    Company Slug
                  </th>
                  <th className="w-150">IP Address</th>
                  <th>Postal Code</th>
                  <th className="w-150">Phone</th>
                  <th className="w-150">Date/ time</th>
                </tr>
              </thead>
              {loaded ? (
                <div className="d-flex justify-content-center three-dot">
                  <ThreeDots
                    height="150"
                    width="150"
                    radius="9"
                    color="#637df4"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <tbody>
                  {history && history.length > 0 ? (
                    history.map((item, index) => {
                      const url = getDetailsLink(item);
                      return (
                        <tr className="table-row">
                          <td>
                           {item.country?.flag_thumbnail ? <img
                              src={item.country.flag_thumbnail}
                              title={item.country?.fullname?.en ?? "-"}
                              className="countryflag"
                              alt="flag"
                            />   : '-'}
                          </td>
                          <td className="w-150">
                            {getName(item)}
                            {getUserType(item)}
                          </td>
                          <td className="w-150">
                            {url ? (
                              <NavLink
                                to={url}
                                className="link"
                                target="_blank"
                              >
                                {getUsername(item)}
                              </NavLink>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="w-150">{item.ip_address ?? "-"}</td>
                          <td> {getPostalCode(item)}</td>
                          <td className="w-150">{getPhone(item)}</td>
                          <td className="w-150">
                            {moment(item.created_at)
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YYYY HH:mm")}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                </tbody>
              )}
            </table>
          </div>
          {totalPage > 1 ? (
            <Pagination
              totalPages={totalPage}
              onPageClick={(page) => setPage(page + 1)}
              key={totalPage}
            />
          ) : (
            ""
          )}
        </Wrapper>
      </Modal>
    </>
  );
};

export default AdVisitorModal;
