import React, { useEffect, useState } from "react";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import SearchableObjDropdownWithImage from "../SearchableDropdown/SearchableObjDropdownWithImage";
import { toast } from "react-toastify";
import { DEBOUNCE_TIME, userTypeName } from "../../../config/constant";
function SubsidiaryFilter({
  sub,
  setSub,
  filters,
  _filters,
  searchSubKeyword,
  _searchSubKeyword,
}) {
  const [susbidiaries, _susbidiaries] = useState([]);
  const [subName, _subName] = useState("");

  useEffect(() => {
    if (!subName || subName.length < 2) {
      return;
    }
    const timer = setTimeout(() => {
      getSubs();
    }, DEBOUNCE_TIME);
    return () => clearTimeout(timer);
  }, [subName]);

  const getSubs = () => {
    API.get(APP_URLS.LIST_SUBSIDIARIES + `/list/all?keyword=${subName}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _susbidiaries(resp.data.items);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          Object.keys(resp.data.data).map(
            (error, index) => (error_message = resp.data.data[error][0])
          );
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  return (
    <SearchableObjDropdownWithImage
      _filters={_filters}
      filters={filters}
      name="subs"
      className="filterBox"
      setChangeValue={(val) => setSub(val)}
      val={sub}
      placeholder={`Select ${userTypeName.DEALER}`}
      dropdownData={susbidiaries}
      func={_subName}
      searchkeyword={searchSubKeyword}
      _searchkeyword={_searchSubKeyword}
    />
  );
}

export default SubsidiaryFilter;
