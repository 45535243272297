import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { CreateAuctionWrapper } from "./style/createAuction";
import Switch from "react-switch";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import SearchableDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableDropdown";
import SearchableObjDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdown";
import { calcTimeWith00SecFormat } from "../../../core/utils/getAmsterdamTime/getTime";
import { getAmstoBrowserTime } from "../../../core/utils/getAmstoBrowsertime/getAmstoBrowserTime";
import alertIcon from "../../../assets/Images/icons/alertIcon.svg";
import { now } from "moment/moment";
import { getTimeAfter_N_Hours } from "../../../core/utils/getTimeAfterN_Hours/getTimeAfterN_Hours";
import { getTimeAfterNumberOfMinutes } from "../../../core/utils";
import {
  recurringFrequencyValue,
  userTypeName,
} from "../../../config/constant";
import SearchableAutoCompleteWithChips from "../../../assets/SharedComponents/SearchableDropdown/SearchableAutoCompleteWithChips";
import ChipList from "../../../assets/SharedComponents/SearchableDropdown/ChipList";

let title = {};
let description = {};

let titleLimit = 250;
let descriptionLimit = 500;
const DEBOUNCE_TIME = 200;
const CreateAuction = () => {
  const [activeLanguages, _activeLanguages] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loading, _loading] = useState(false);
  const [countries, setcountries] = useState(
    JSON.parse(localStorage.getItem("countries"))
  );
  const [products, _products] = useState(
    JSON.parse(localStorage.getItem("productType"))
  );
  const [dropdownValues, _dropdownValues] = useState([]);
  const [dateAdsVal, _dateAdsVal] = useState(2000);
  //Preview Values
  const [productValue, _productValue] = useState("");
  const [titleValue, _titleValue] = useState("");
  const [product, _product] = useState("");
  const [buyerValue, _buyerValue] = useState("All");
  const [sellerValue, _sellerValue] = useState("All");
  const [startDateTimeValue, _startDateTimeValue] = useState(new Date());
  const [endDateTimeValue, _endDateTimeValue] = useState(new Date());
  const [registrationStartsAt, _registrationStartsAt] = useState(new Date());
  const [countryValue, _countryValue] = useState("");
  const [countryflag, _countryflag] = useState("");
  //Subsidiaries
  const [subName, _subName] = useState("");
  const [subsidiaryData, _subsidiaryData] = useState([]);
  const [subsidiaryValue, _subsidiaryValue] = useState([]);
  const today = new Date(getAmstoBrowserTime());
  const yesterday = new Date().setDate(new Date().getDate() - 1);
  const [timeIntervalBetweenAds, setTimeIntervalBetweenAds] = useState(""); // MM:HH format
  const [adsTimeIntervalIntoSeconds, _adsTimeIntervalIntoSeconds] =
    useState("");
  const [timeIntervalError, setTimeIntervalError] = useState("");
  const [selectedValuesData, _selectedValuesData] = useState([]);
  const [chips, setChips] = useState(selectedValuesData || []);
  const navigate = useNavigate();

  useEffect(() => {
    getActiveLanguages();
    setDropdownValues();
    let languages = activeLanguages;
    let code = "";
    for (let i = 0; i < languages.length; i++) {
      code = languages[i].code;
      title[code] = "";
      description[code] = "";
    }
  }, []);

  useEffect(() => {
    if (countryValue) {
      getFlag();
    }
    if (productValue) {
      getProduct();
    }
  }, [countryValue, productValue]);

  useEffect(() => {
    let timer = null;
    if (subName !== undefined && subName !== null)
      timer = setTimeout(() => {
        getActiveSubsidiary();
      }, DEBOUNCE_TIME);
    return () => clearTimeout(timer);
  }, [countryValue, subName]);

  const getActiveLanguages = () => {
    API.get(APP_URLS.LANGUAGE_LIST)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let languages = resp.data.languages;
          _activeLanguages(languages);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setDropdownValues = () => {
    let values = [];
    for (let i = 0; i < 60; i++) {
      values.push(
        <option value={i < 10 ? `0${i}` : i} key={i}>
          {i < 10 ? `0${i}` : i}
        </option>
      );
    }
    _dropdownValues(values);
  };

  const handleTitle = (val, code) => {
    title[code] = val;
    if (code === "en") {
      _titleValue(val);
    }
  };

  const handleDesc = (val, code) => {
    description[code] = val;
  };

  const handleSwitch = () => {
    setChecked(!checked);
    setChips([]);
  };

  const getFlag = () => {
    let values = JSON.parse(localStorage.getItem("countries"));
    for (let i = 0; i < values.length; i++) {
      if (values[i].id == countryValue) {
        _countryflag(values[i].flag_thumbnail);
        break;
      }
    }
  };

  const getProduct = () => {
    for (let i = 0; i < products.length; i++) {
      if (products[i].id == productValue) {
        _product(products[i].item_name.en);
      }
    }
  };

  const getActiveSubsidiary = () => {
    API.get(
      APP_URLS.ACTIVE_SUBSIDIARY +
        `?keyword=${subName}` +
        (countryValue ? `&filter[country_id]=${countryValue}` : ``)
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          const subs = Object.entries(resp?.data?.subsidiary ?? {}).map(
            ([id, name]) => ({
              id: parseInt(id),
              name: name,
            })
          );
          _subsidiaryData(subs);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data?.email !== undefined) {
          {
            Object.keys(resp.data.data.email).map(
              (error, index) => (error_message = resp.data.data.email[error])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setChangeValue = (val) => {
    formik.setFieldValue("country", val);
    _countryValue(val);
    setChips([]);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      startDate: "",
      endDate: "",
      startDateAds: "",
      endDateAds: "",
      secondeExtension: "",
      recurring: "",
      productType: "",
      country: "",
      description: "",
      seller: "0",
      buyer: "0",
      decisionTime: "",
      file: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
      description: Yup.string().required(),
      startDate: Yup.date()
        .required("Start Date and Time is Required")
        .max(
          getTimeAfterNumberOfMinutes(endDateTimeValue, -1),
          "Start Date should be at least 1 minute smaller than end date"
        )
        .min(moment(today).format(), "Date/Time cannot be in the past"),
      endDate: Yup.date()
        .required("End Date and Time is Required")
        .min(moment(today).format(), "Date/Time cannot be in the past")
        .test(
          "is-valid-end-date",
          "End date is not valid for the selected frequency",
          function (value) {
            const { startDate, recurring } = this.parent;
            if (!startDate || !value || !recurring) return true; // Skip validation if any of the dates or frequency is not set

            const start = moment(startDate);
            const end = moment(value);
            const diff = end.diff(start, "hours");

            switch (recurring) {
              case recurringFrequencyValue.WEEKLY: // Weekly
                return diff <= 7 * 24;
              case recurringFrequencyValue.MONTHLY: // Monthly
                return end.diff(start, "months") < 1;
              case recurringFrequencyValue.YEARLY: // Yearly
                return end.diff(start, "years") < 1;
              default:
                return true;
            }
          }
        ),
      startDateAds: Yup.date()
        .required("Start insert Date Ads can't be blank")
        .min(
          getTimeAfter_N_Hours(startDateTimeValue, -2000),
          "Minimum value should be at most 2000 hours less than Auction start date"
        )
        .max(
          getTimeAfter_N_Hours(startDateTimeValue, -2),
          "Maximum value should be at least 2 hours less than Auction start date"
        ),
      endDateAds: Yup.date()
        .required("End insert Date Ads can't be blank")
        .min(
          getTimeAfter_N_Hours(registrationStartsAt, 1),
          "Minimum value should be at least 60 minutes greater than ads insert start time"
        )
        .max(
          getTimeAfterNumberOfMinutes(startDateTimeValue, -1),
          "Maximum value should be at least 1 minute less than Auction start date"
        ),
      secondeExtension: Yup.number()
        .required("Extension Period can't be blank")
        .min(0, "Value can't be negative")
        .max(59, "Maximum value should be 59"),
      recurring: Yup.string().required("Please select a Frequency value"),
      productType: Yup.string().required("Please select a Product type"),
      country: Yup.string().required("Please select a Country"),
      seller: Yup.string().required("Please select a Seller"),
      buyer: Yup.string().required("Please select a Buyer"),
      decisionTime: Yup.string()
        .required("Decision Time can't be blank")
        .matches(/^(?:[0-9][0-9]|59):[0-5][0-9]$/, "Time is not correct"),
      file: "",
    }),
    onSubmit: (values) => {
      let data = {};
      data["title"] = title;
      data["description"] = description;
      data["first_start_event"] = moment(values.startDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      data["first_end_event"] = moment(values.endDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const auctionStartDateMinutes = Math.floor(
        startDateTimeValue.getTime() / 60000
      ); // convert miliseconds into minutes
      const startAdsMinutes = Math.floor(
        Number(formik?.values?.startDateAds || 0) / 60000
      );
      const endAdsMinutes = Math.floor(
        Number(formik?.values?.endDateAds || 0) / 60000
      );
      data["start_record_before_event"] =
        auctionStartDateMinutes - startAdsMinutes;
      data["end_record_before_event"] = auctionStartDateMinutes - endAdsMinutes;
      data["recurring"] = values.recurring;
      data["second_extension"] = values.secondeExtension;
      data["product_category_type_id"] = values.productType;
      data["country_id"] = values.country;
      data["decision_time"] = values.decisionTime;
      data["time_interval_ads"] = adsTimeIntervalIntoSeconds;
      data["display_for"] = values.buyer;
      data["seller_allowed"] = values.seller;
      if (checked) {
        data["exclusive_seller_sub_id"] = subsidiaryValue;
      }
      if (!loading) {
        _loading(true);
        API.post(APP_URLS.CREATE_AUCTION, data)
          .then((res) => {
            const resp = res.data;
            _loading(false);
            if (resp.success === true) {
              let respID = resp.data.auctionEvent.id;
              toast.success("Auction has been created successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              formik.resetForm();
              navigate("/auction-events");
              let inputs = document.querySelectorAll(".langaugeFields");
              inputs.forEach((input) => {
                input.value = "";
              });
            } else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(function (error) {
            const resp = error.response;
            _loading(false);
            let error_message = "";
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) => (error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error;
            } else {
              error_message = resp.data.message;
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    },
  });

  const submitForm = (e) => {
    let languages = activeLanguages;
    let code = "";

    if (!timeIntervalBetweenAds) {
      toast.error("Time interval ads is required.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    for (let i = 0; i < languages.length; i++) {
      code = languages[i].code;
      if (title[code] != "" && description[code] != "") {
        if (title[code]?.length > titleLimit) {
          toast.error(`Title(${code}) must be at most 250 characters long `, {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }

        if (description[code]?.length > descriptionLimit) {
          toast.error(
            `Description(${code}) must be at most 500 characters long `,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          return;
        }
      }
    }
    for (let i = 0; i < languages.length; i++) {
      code = languages[i].code;
      if (title[code] != "" && description[code] != "") {
        formik.setFieldValue("title", JSON.stringify(title));
        formik.setFieldValue("description", JSON.stringify(description));
        setTimeout(() => formik.handleSubmit(e), 100);
        break;
      } else if (i === languages.length - 1) {
        formik.handleSubmit(e);
      }
    }
  };
  const convertIntoHours = (date) => {
    const diff = moment(date) - now();
    _dateAdsVal(Math.floor(diff / 3600 / 1000));
  };

  const handleTimeInterval = (e) => {
    // Regular expression to validate the time format
    const timeIntervalString = e.target.value;
    setTimeIntervalBetweenAds(timeIntervalString);
    const timeRegex = /^(?:0[0-9]|10):[0-5][0-9]$/;
    // Check if the time format is valid
    if (!timeRegex.test(timeIntervalString)) {
      _adsTimeIntervalIntoSeconds(0);
      setTimeIntervalError(
        "Invalid time format. Please use MM:SS where minutes are between 0 and 10."
      );
      return null;
    } else setTimeIntervalError("");

    // Split the time into minutes and seconds
    const timeArray = timeIntervalString.split(":");

    // Convert minutes and seconds to integers
    const minutes = parseInt(timeArray[0], 10);
    const seconds = parseInt(timeArray[1], 10);

    // Check if minutes are within the valid range
    if (
      minutes < 0 ||
      minutes > 10 ||
      (minutes == 10 && seconds > 0) ||
      (minutes == 0 && seconds == 0)
    ) {
      _adsTimeIntervalIntoSeconds(0);
      setTimeIntervalError(
        "Invalid minutes. Please use a value between 1 and 10."
      );
      return null;
    } else setTimeIntervalError("");

    // Calculate the total seconds
    const totalSeconds = minutes * 60 + seconds;
    _adsTimeIntervalIntoSeconds(totalSeconds);
    return;
  };

  //to set array of Ids of selected options
  const getSelectedData = () => {
    const selectedOptionIds = chips.map((option) => parseInt(option.id));
    _subsidiaryValue(selectedOptionIds);
  };

  //set chips from dropdown on selecting values from dropdown
  const handleChipChange = (value) => {
    setChips(value);
  };

  //removing chip from selected chips
  const handleDelete = (stringToDelete) => {
    const updatedChips = chips?.filter(
      (value) => value.id !== stringToDelete.id
    );
    setChips(updatedChips);
  };
  return (
    <>
      <CreateAuctionWrapper>
        <div className="mt-2">
          <div className="backContainer ms-4" onClick={() => navigate(-1)}>
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <form
          className="auctionMainContainer"
          onSubmit={(e) => {
            submitForm(e);
            e.preventDefault();
          }}
        >
          <div className="mb-2">
            <h1>CREATE AUCTION</h1>
          </div>
          <div className="actionContainer d-flex justify-content-end">
            <button
              type="submit"
              className="btn save-button"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}{" "}
            </button>
          </div>

          <div className="container">
            <div className="row">
              <label className="fieldLabel mb-2">Title</label>
              {activeLanguages.map((item, index) => {
                let flag = countries.find(
                  (o) => o.id === item.country_id
                )?.flag_thumbnail;
                let itemCode = item.code;
                return (
                  <div className="col-lg-3 col-md-6 auction-form" key={index}>
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        <span className="px-2">
                          <img
                            className="countryflag"
                            src={flag}
                            title={item.name}
                          />
                        </span>
                        <span className="langName">{itemCode}</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control langaugeFields"
                        id=""
                        onChange={(e, code = itemCode) => {
                          handleTitle(e.target.value, code);
                        }}
                        placeholder=""
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <label className="fieldLabel mt-3 mb-2">Description</label>
              {activeLanguages.map((item, index) => {
                let flag = countries.find(
                  (o) => o.id === item.country_id
                )?.flag_thumbnail;
                let itemCode = item.code;
                return (
                  <div className="col-lg-3 col-md-6 auction-form" key={index}>
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        <span className="px-2">
                          <img
                            className="countryflag"
                            src={flag}
                            title={item.name}
                          />
                        </span>
                        <span className="langName">{itemCode}</span>{" "}
                      </label>
                      <textarea
                        className="form-control langaugeFields"
                        id="language"
                        rows="1"
                        onChange={(e, code = itemCode) => {
                          handleDesc(e.target.value, code);
                        }}
                      ></textarea>
                    </div>
                  </div>
                );
              })}
              {formik.touched.title &&
              formik.errors.title &&
              formik.touched.description &&
              formik.errors.description ? (
                <div className="error">
                  Atleast one title and its description is required.
                </div>
              ) : null}
            </div>
            {/* <div className="error">Please fill atleast one title and its description </div> */}
            <div className="row mt-4">
              <div className="col-lg-6 col-sm-12">
                <div className="auction-form">
                  <div className="row">
                    <div className="col-lg-4 form-group date-time-picker">
                      <label htmlFor="startDate" className="form-label">
                        Auction Start Date
                      </label>
                      <Datetime
                        name="startDate"
                        id="startDate"
                        dateFormat="DD-MM-YYYY"
                        value={formik.values.startDate}
                        inputProps={{
                          readOnly: true,
                          placeholder: "Select a date and time",
                        }}
                        isValidDate={(date) => {
                          return date.isAfter(yesterday);
                        }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "startDate",
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                          _startDateTimeValue(
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.startDate && formik.errors.startDate ? (
                        <div className="error">{formik.errors.startDate}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 form-group date-time-picker">
                      <label htmlFor="endDate" className="form-label">
                        Auction End Date
                      </label>
                      <Datetime
                        name="endDate"
                        id="endDate"
                        dateFormat="DD-MM-YYYY"
                        value={formik.values.endDate}
                        inputProps={{
                          readOnly: true,
                          disabled: !startDateTimeValue,
                          placeholder: "Select a date and time",
                        }}
                        isValidDate={(date) => {
                          return date.isAfter(yesterday);
                        }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "endDate",
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                          _endDateTimeValue(
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.endDate && formik.errors.endDate ? (
                        <div className="error">{formik.errors.endDate}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 form-group">
                      <label htmlFor="recurring" className="form-label">
                        Frequency
                      </label>
                      <select
                        name="recurring"
                        id="recurring"
                        value={formik.values.recurring}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option hidden>Select</option>
                        <option value="0">One Time</option>
                        <option value="1">Weekly</option>
                        <option value="2">Monthly</option>
                        <option value="3">Yearly</option>
                      </select>
                      {formik.touched.recurring && formik.errors.recurring ? (
                        <div className="error">{formik.errors.recurring}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 form-group">
                      <label htmlFor="startDateAds" className="form-label">
                        Ads Insert Start Time (H)
                      </label>
                      <Datetime
                        name="startDateAds"
                        id="startDateAds"
                        dateFormat="DD-MM-YYYY"
                        value={formik.values.startDateAds}
                        inputProps={{
                          readOnly: true,
                          placeholder: "Select a date and time",
                        }}
                        isValidDate={(date) => {
                          return date.isAfter(yesterday);
                        }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "startDateAds",
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                          convertIntoHours(
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                          _registrationStartsAt(
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.startDateAds &&
                      formik.touched.endDateAds &&
                      formik.errors.startDateAds ? (
                        <div className="error">
                          {formik.errors.startDateAds}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 form-group">
                      <label htmlFor="endDateAds" className="form-label">
                        End Insert Time Ads
                      </label>
                      <Datetime
                        name="endDateAds"
                        id="endDateAds"
                        dateFormat="DD-MM-YYYY"
                        value={formik.values.endDateAds}
                        inputProps={{
                          readOnly: true,
                          placeholder: "Select a date and time",
                        }}
                        isValidDate={(date) => {
                          return date.isAfter(yesterday);
                        }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "endDateAds",
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.startDateAds &&
                      formik.touched.endDateAds &&
                      formik.errors.endDateAds ? (
                        <div className="error">{formik.errors.endDateAds}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 form-group">
                      <label htmlFor="secondeExtension" className="form-label">
                        Extension Period (S)
                      </label>
                      <select
                        name="secondeExtension"
                        id="secondeExtension"
                        value={formik.values.secondeExtension}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option hidden>Select</option>
                        {dropdownValues}
                      </select>
                      {formik.touched.secondeExtension &&
                      formik.errors.secondeExtension ? (
                        <div className="error">
                          {formik.errors.secondeExtension}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label htmlFor="productType" className="form-label">
                        Product Type
                      </label>
                      <select
                        name="productType"
                        id="productType"
                        value={formik.values.productType}
                        onChange={(e) => {
                          formik.handleChange(e);
                          _productValue(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option hidden>Select</option>
                        {products.map((item, index) => {
                          return (
                            <option
                              value={item?.product_categories[0]?.pivot?.id}
                              key={index}
                            >
                              {item?.item_name.en}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.productType &&
                      formik.errors.productType ? (
                        <div className="error">{formik.errors.productType}</div>
                      ) : null}
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <SearchableDropdown
                        name="country"
                        className="form-control"
                        setChangeValue={(val) => setChangeValue(val)}
                        val={countryValue}
                        placeholder="Select Country"
                        dropdownData={JSON.parse(
                          localStorage.getItem("countries")
                        )}
                      />
                      {formik.touched.country && formik.errors.country ? (
                        <div className="error">{formik.errors.country}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label htmlFor="decisionTime" className="form-label">
                        Decision Time (HH:MM)
                        <img
                          src={alertIcon}
                          className="tooltipIcon"
                          title="Seller has to accept or reject bids in between this time period when auction ends."
                        />
                      </label>
                      <input
                        type="text"
                        name="decisionTime"
                        id="decisionTime"
                        placeholder="00:00"
                        className="form-control"
                        value={formik.values.decisionTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.decisionTime &&
                      formik.errors.decisionTime ? (
                        <div className="error">
                          {formik.errors.decisionTime}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="timeIntervalAds" className="form-label">
                        Time Interval Ads (MM:SS)
                      </label>
                      <input
                        type="text"
                        name="timeIntervalAds"
                        id="timeIntervalAds"
                        placeholder="00:00"
                        className="form-control"
                        value={timeIntervalBetweenAds}
                        onChange={handleTimeInterval}
                      />
                      {formik.touched.timeIntervalAds &&
                      formik.errors.timeIntervalAds ? (
                        <div className="error">
                          {formik.errors.timeIntervalAds}
                        </div>
                      ) : null}
                      {timeIntervalError ? (
                        <div className="error">{timeIntervalError}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="formInput mb-4">
                    <label htmlFor="seller" className="form-label mb-3">
                      <b>Type Buyer</b>
                    </label>
                    <div className="radioOptions ps-5">
                      <div className="options">
                        <input
                          type="radio"
                          id="all"
                          name="buyer"
                          value="0"
                          defaultChecked
                          onChange={() => {
                            _buyerValue("All");
                            formik.setFieldValue("buyer", "0");
                          }}
                        />
                        <label htmlFor="html">All</label>
                      </div>
                      <div className="options">
                        <input
                          type="radio"
                          id="company"
                          name="buyer"
                          value="1"
                          onChange={() => {
                            _buyerValue("Company");
                            formik.setFieldValue("buyer", "1");
                          }}
                        />
                        <label htmlFor="css">{userTypeName.DEALER}</label>
                      </div>
                      <div className="options">
                        <input
                          type="radio"
                          id="Private"
                          name="buyer"
                          value="2"
                          onChange={() => {
                            _buyerValue("Private");
                            formik.setFieldValue("buyer", "2");
                          }}
                        />
                        <label htmlFor="css">Private</label>
                      </div>
                    </div>
                    {formik.touched.buyer && formik.errors.buyer ? (
                      <div className="error">{formik.errors.buyer}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-1">
                <div className="v1"></div>
              </div>

              <div className="col-lg-5 com-sm-12">
                <div className="rightFormInputContainer">
                  <div className="preview">
                    <label className="form-label">
                      <b>Preview</b>
                    </label>
                    <div className="previewCard">
                      <div className="previewCardContainer">
                        <div className="cardHeader">
                          <div className="cardContent">
                            <div className="cardContentHeader">
                              <p>
                                {title["en"] !== ""
                                  ? title["en"]
                                  : "Auction Title"}
                              </p>
                            </div>
                            <div className="cardContentContainer">
                              <div className="cardContentText">
                                <p className="cardContentTextHead">
                                  Allowed Product :
                                </p>
                                <p className="cardContentTextContent">
                                  {" "}
                                  {product}
                                </p>
                                <p></p>
                              </div>
                              <div className="cardContentText">
                                <p className="cardContentTextHead">
                                  Allowed Buyer :
                                </p>
                                <p className="cardContentTextContent">
                                  {" "}
                                  {buyerValue}
                                </p>
                                <p></p>
                              </div>
                              <div className="cardContentText">
                                <p className="cardContentTextHead">
                                  Allowed Seller :
                                </p>
                                <p className="cardContentTextContent me-2">
                                  {" "}
                                  {sellerValue}
                                </p>
                                <div className="cardContentTextContent">
                                  <p>From</p>
                                </div>
                                {countryflag ? (
                                  <span className="ps-3">
                                    <img
                                      src={countryflag}
                                      className="countryflag"
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <hr />
                        </div>
                        <div className="cardFooter">
                          <p className="cardFooterHeading">
                            Start Date :
                            {startDateTimeValue ? (
                              <span className="cardFooterContent">
                                {" "}
                                {moment(startDateTimeValue)
                                  .parseZone("")
                                  .format("DD-MM-YYYY HH:mm")}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                          <p className="cardFooterHeading">
                            End Date :
                            {endDateTimeValue ? (
                              <span className="cardFooterContent">
                                {" "}
                                {moment(endDateTimeValue)
                                  .parseZone("")
                                  .format("DD-MM-YYYY HH:mm")}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-5" />
                  <div className="formInput mb-4">
                    <label htmlFor="seller" className="form-label mb-3">
                      <b>Type Seller</b>
                    </label>
                    <div className="radioOptions ps-5">
                      <div className="options">
                        <input
                          type="radio"
                          id="all"
                          name="seller"
                          value="0"
                          defaultChecked
                          onChange={() => {
                            _sellerValue("All");
                            formik.setFieldValue("seller", "0");
                          }}
                        />
                        <label htmlFor="html">All</label>
                      </div>
                      <div className="options">
                        <input
                          type="radio"
                          id="company"
                          name="seller"
                          value="1"
                          onChange={() => {
                            _sellerValue("Company");
                            formik.setFieldValue("seller", "1");
                          }}
                        />
                        <label htmlFor="css">{userTypeName.DEALER}</label>
                      </div>
                      <div className="options">
                        <input
                          type="radio"
                          id="private"
                          name="seller"
                          value="2"
                          onChange={() => {
                            _sellerValue("Private");
                            formik.setFieldValue("seller", "2");
                          }}
                        />
                        <label htmlFor="javascript">Private</label>
                      </div>
                    </div>
                    {formik.touched.seller && formik.errors.seller ? (
                      <div className="error">{formik.errors.seller}</div>
                    ) : null}
                  </div>
                  {sellerValue === "Company" ? (
                    <div className="formInput auction-form">
                      <ChipList chips={chips} onDelete={handleDelete} />
                      <div className="inputSwitchgroup mt-3 mb-5 ps-5 d-flex justify-content-between flex-wrap">
                        <div className="leftSwitchChild mb-2">
                          <Switch
                            onChange={handleSwitch}
                            checked={checked}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            offColor="#D9D9D9"
                            onColor="#7589EC"
                          />
                          <p>{`Exclusive Seller ${userTypeName.DEALER}`}</p>
                        </div>
                        <div className="rightSwitchChild resultSearchOuter">
                          <SearchableAutoCompleteWithChips
                            options={subsidiaryData}
                            onChange={handleChipChange}
                            chips={chips}
                            disabled={!checked}
                            func={_subName}
                            keyword={subName}
                            getSelectedData={getSelectedData}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </CreateAuctionWrapper>
    </>
  );
};

export default CreateAuction;
