import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./assets/SharedComponents/scroller/ScrollToTop";
import { MaintenanceProvider } from "./context/MaintenanceContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <MaintenanceProvider>
      <App />
    </MaintenanceProvider>
  </BrowserRouter>
);
