import React, { useEffect, useState } from "react";
import { CompanyWrapper } from "./style/company";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../../../assets/SharedComponents/Pagination";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import { Button, Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import moment from "moment";
import profilePic from "../../../assets/Images/image/noImage.svg";
import searchIcon from "../../../assets/Images/icons/faSearchLite.svg";
import Sorting from "../../../assets/SharedComponents/Sorting/Sorting";
import StaticFilter from "../../../assets/SharedComponents/Filters/StaticFilter";
import CountryFilter from "../../../assets/SharedComponents/Filters/CountryFilter";
import { formatCurrency } from "../../../core/utils";
import UserFilter from "../../../assets/SharedComponents/Filters/UserFilter";
import {
  hotdealDiscountType,
  productStatusOptions,
  SUBSIDIARY,
  userTypeName,
} from "../../../config/constant";
import download from "downloadjs";

const productStatusOptionsList = Object.keys(productStatusOptions).map(
  (key) => ({ name: key, value: productStatusOptions[key] })
);

const hotdealDiscountOptionsList = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Applied",
    value: hotdealDiscountType.applied,
  },
  {
    name: "Not Applied",
    value: hotdealDiscountType.notApplied,
  },
];

const Company = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [countries, _countries] = useState(
    JSON.parse(localStorage.getItem("countries"))
  );
  const [filteredCountries, _filteredCountries] = useState(
    JSON.parse(localStorage.getItem("countries"))
  );

  const [data, _data] = useState([]);
  const [total, _total] = useState(0);

  // Initialize with items' hotdeal_discount

  const [trashed, _trashed] = useState(
    searchParams.get("trashed") !== null
      ? searchParams.get("trashed") === "false"
        ? false
        : true
      : true
  );
  const [page, setPage] = useState(
    query.get("page") !== null ? parseInt(query.get("page")) : 1
  );
  const [totalPage, _totalPage] = useState(0);
  const [countValue, _countValue] = useState(
    query.get("per_page") !== null ? query.get("per_page") : ""
  );

  const [startDate, _startDate] = useState(
    query.get("start_plan") !== null ? query.get("start_plan") : ""
  );
  const [endDate, _endDate] = useState(
    query.get("end_plan") !== null ? query.get("end_plan") : ""
  );

  const [showData, _showData] = useState(false);
  const [countryCode, _countryCode] = useState(
    query.get("country_code") !== null ? query.get("country_code") : ""
  );
  const [vat, _vat] = useState(
    query.get("vat") !== null ? query.get("vat") : ""
  );

  const [sort, _sort] = useState(
    searchParams.get("sort") !== null ? searchParams.get("sort") : "-created_at"
  );

  const [country, setCountry] = useState(
    query.get("country_id") !== null ? query.get("country_id") : ""
  );
  const [status, _status] = useState(
    query.get("status") !== null ? query.get("status") : ""
  );

  const [hotdealDiscount, _hotdealDiscount] = useState(
    query.get("hotdealDiscount") !== null ? query.get("hotdealDiscount") : ""
  );

  const [searchkeyword, _searchkeyword] = useState(
    query.get("name") !== null ? query.get("name") : ""
  );
  const [filters, _filters] = useState(false);
  const [userID, _userID] = useState("");
  const [loading, _loading] = useState(false);

  const day = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    .toJSON()
    .slice(0, 10);

  useEffect(() => {
    getCompany();
  }, [
    page,
    status,
    hotdealDiscount,
    countValue,
    country,
    startDate,
    endDate,
    searchkeyword,
    trashed,
    sort,
    userID,
  ]);

  useEffect(() => {
    if (countryCode !== "") {
      document.getElementById("countryCode").value = countryCode;
    }
    if (vat === "" && countryCode === "") {
      getCompany();
    }
  }, [countryCode, vat]);

  const getCompany = () => {
    _loading(true);
    API.get(`${APP_URLS.LIST_SUBSIDIARY}${getFilters()}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //applied filters
  function getFilters() {
    return `?page=${page}&per_page=${countValue}&sort=${sort}&filter[name]=${searchkeyword}&filter[owner_id]=${userID}&filter[country_id]=${country}&filter[status]=${status}&filter[start_date]=${startDate}&filter[end_date]=${endDate}&prefix_vat=${countryCode}&filter[vat_number]=${vat}&with_trashed=${
      trashed === true ? 1 : 0
    }&filter[hotdeal_discount]=${hotdealDiscount}`;
  }

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (
      (func === _countValue && searchValue !== countValue) ||
      (func === _searchkeyword && searchValue !== searchkeyword)
    ) {
      if (searchValue !== "") {
        _totalPage(0);
        setPage(1);
      }
    }
  };

  const setValue = (val) => {
    document.getElementById("countryCode").value = val.country_name;
    _countryCode(val.country_name);
    setTimeout(() => document.getElementById("vatField").focus(), 200);
  };

  const setUserID = (updateUser, id) => {
    updateUser(id);
    _totalPage(0);
    setPage(1);
  };

  const filterData = (value) => {
    let search = value.toUpperCase();
    if (value.length > 0) {
      let newData = [...countries];
      let val = newData.filter((o) => o.country_name.includes(search));
      _filteredCountries(val);
    } else {
      _filteredCountries(countries);
    }
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    setCountry("");
    _status("");
    _startDate("");
    _endDate("");
    _vat("");
    _userID("");
    _sort("created_at");
    _trashed(true);
    _hotdealDiscount("");
    document.getElementById("countryCode").value = "";
    _countryCode("");
    if (
      searchkeyword !== "" ||
      countValue !== "" ||
      country !== "" ||
      startDate !== "" ||
      endDate !== "" ||
      countryCode !== "" ||
      vat !== "" ||
      hotdealDiscount !== "" ||
      trashed !== true ||
      sort !== "created_at" ||
      userID !== ""
    ) {
      _totalPage(0);
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  const setChangeValue = (item) => {
    setCountry(item);
    if (item !== country) {
      _totalPage(0);
      setPage(1);
    }
  };

  const diffdates = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };

  const setSort = (val) => {
    _sort(val);
    if (sort !== val) {
      setPage(1);
      _totalPage(0);
    }
  };

  const handleChangeHotDealDiscount = (event, index, id) => {
    event.preventDefault();
    _data((prevData) => {
      return prevData.map((item, i) =>
        i === index
          ? { ...item, hotdeal_discount: event.target.checked ? 1 : 0 }
          : item
      );
    });
    try {
      API.patch(`/admin/subsidiaries/${id}/hotdeal_discount`, {
        hotdeal_discount: event.target.checked ? 1 : 0,
      }).then(() => {
        toast.success("Hotdeal discount updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleExtractReport = () => {
    API.get(`${APP_URLS.LIST_SUBSIDIARY}/download_excel_file${getFilters()}`, {
      responseType: "blob",
    })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_subsidiaries_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <CompanyWrapper>
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 offset-md-4 mb-2">
              <h1>{userTypeName.DEALER_CAPITAL}</h1>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end info-wrap">
            <div className="justify-content-end mb-2 d-flex gap-2 select-info align-items-center info-wrap">
              <div className="searchBox">
                <div className="position-relative">
                  <input
                    type="text"
                    id="countryCode"
                    className="countryCode"
                    placeholder="Prefix"
                    onChange={(e) => filterData(e.target.value)}
                    onFocus={() => _showData(true)}
                    onBlur={() => setTimeout(() => _showData(false), 200)}
                  />
                  {showData ? (
                    <ul className="searchResult">
                      {filteredCountries.map((item, index) => {
                        return (
                          <li
                            className="d-flex justify-content-between align-items-center"
                            onClick={() => setValue(item)}
                            key={index}
                            title={item.fullname}
                          >
                            <span>{item.country_name}</span>
                            <img
                              className="codeFlag"
                              src={item.flag_thumbnail}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
                <input
                  type="text"
                  placeholder="Enter VAT Number"
                  className={
                    (countryCode !== "" ? "" : "disabled") + " vatField"
                  }
                  disabled={!countryCode}
                  id="vatField"
                  value={vat}
                  onChange={(e) => {
                    _vat(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      _totalPage(0);
                      setPage(1);
                      getCompany();
                    }
                  }}
                  onBlur={() => {
                    _totalPage(0);
                    setPage(1);
                    getCompany();
                  }}
                />
                <img
                  src={searchIcon}
                  alt="search"
                  onClick={() => getCompany()}
                />
              </div>
              <StaticFilter
                placeholder="Subscription Status"
                data={productStatusOptionsList}
                handleChange={(e) => {
                  _status(e.target.value);
                  _totalPage(0);
                  setPage(1);
                }}
                value={status}
              />
              <StaticFilter
                placeholder="Hotdeal Discount"
                data={hotdealDiscountOptionsList}
                handleChange={(e) => {
                  _hotdealDiscount(e.target.value);
                  _totalPage(0);
                  setPage(1);
                }}
                value={hotdealDiscount}
              />
              <UserFilter
                _filters={_filters}
                filters={filters}
                setUser={(val) => setUserID(_userID, val)}
                user={userID}
                placeholder={"Select Owner"}
              />
              <CountryFilter value={country} handleChange={setChangeValue} />
              <input
                type="text"
                className="filterBox date"
                id="start_date"
                placeholder="Start"
                onFocus={() =>
                  (document.getElementById("start_date").type = "date")
                }
                onBlur={() =>
                  startDate === ""
                    ? (document.getElementById("start_date").type = "text")
                    : ""
                }
                value={startDate}
                max={endDate ? endDate : day}
                onChange={(e) => _startDate(e.target.value)}
              />
              <input
                type="text"
                className="filterBox date"
                id="end_date"
                placeholder="End"
                onFocus={() =>
                  (document.getElementById("end_date").type = "date")
                }
                onBlur={() =>
                  endDate === ""
                    ? (document.getElementById("end_date").type = "text")
                    : ""
                }
                value={endDate}
                max={day}
                onChange={(e) => _endDate(e.target.value)}
              />
              <SearchKey
                onSearchClick={(search) =>
                  handleSearchChange(_searchkeyword, search)
                }
                id="searchData"
                val={searchkeyword}
                filters={filters}
                _filters={_filters}
                placeholder="Search by Title"
              />
              <RecordCount
                onSearchClick={(search) =>
                  handleSearchChange(_countValue, search)
                }
                id="pageCount"
                val={countValue}
                filters={filters}
                _filters={_filters}
              />
              <Button
                onClick={handleExtractReport}
                className="extract-report ml-2"
                variant="outlined"
              >
                Extract report
              </Button>
              <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon
                  className="filter-icon"
                  role="button"
                  onClick={clearFilter}
                />
              </Tooltip>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
              <span className="text-start">
                Total Records : <b>{total}</b>
              </span>
            </div>
            <div className="mt-1">
              <input
                type="checkbox"
                checked={!trashed}
                onChange={() => {
                  _totalPage(0);
                  setPage(1);
                  _trashed(!trashed);
                }}
              />
              <span className="ms-2">Hide Deleted Data</span>
            </div>
          </div>
          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th className="w-200">
                    Name
                    <br />
                    Name of owner
                  </th>
                  <th className="w120">
                    <span className="d-block">{`${userTypeName.DEALER} Status`}</span>
                    <span className="d-block">Subscription Status</span>
                    <span>VAT Number</span>
                  </th>
                  <th className="w120">Plan Name </th>
                  <th className="time-start">
                    Created At
                    <Sorting
                      sortType="created_at"
                      sort={sort}
                      handleSort={setSort}
                    />
                    <br />
                    Deleted At
                    <Sorting
                      sortType="deleted_at"
                      sort={sort}
                      handleSort={setSort}
                    />
                    <br />
                  </th>
                  <th className="w120">
                    <span className="d-block fieldTitle">(Subscription)</span>
                    <span className="d-block">Start Time</span>
                    <span>End Time</span>
                  </th>
                  <th className="time-start d-flex flex-column ">
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="mxw-90">Number of Ads Active</span>
                      <Sorting
                        sortType="ads_active"
                        sort={sort}
                        handleSort={setSort}
                      />
                    </div>
                  </th>
                  <th className="time-start d-flex flex-column ">
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="mxw-90">Number of Ads From Start</span>
                      <Sorting
                        sortType="total_ads"
                        sort={sort}
                        handleSort={setSort}
                      />
                    </div>
                  </th>
                  <th className="time-start d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="mxw-80">Vehicle Bought</span>
                      <Sorting
                        sortType="vehicle_bought"
                        sort={sort}
                        handleSort={setSort}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="mxw-80">Vehicle Sold</span>
                      <Sorting
                        sortType="vehicle_sold"
                        sort={sort}
                        handleSort={setSort}
                      />
                    </div>
                  </th>
                  <th className="time-start d-flex flex-column ">
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="mxw-90">
                        Number of Ads To-Do in Auction
                      </span>
                      <Sorting
                        sortType="ads_in_auction"
                        sort={sort}
                        handleSort={setSort}
                      />
                    </div>
                  </th>
                  <th className="time-start d-flex flex-column ">
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="mxw-90">Number of Members</span>
                      <Sorting
                        sortType="members"
                        sort={sort}
                        handleSort={setSort}
                      />
                    </div>
                  </th>
                  {/* <th className="w80">Rating</th> */}
                  <th className="w100">Sanctions</th>
                  <th className="w100">hot deals discount</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="d-flex justify-content-center three-dot">
                    <ThreeDots
                      height="150"
                      width="150"
                      radius="9"
                      color="#637df4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : data.length > 0 ? (
                  data.map((item, index) => {
                    let diffDays = 0;
                    if (item.subscriptions.length > 0) {
                      diffDays = diffdates(
                        new Date(
                          item.subscriptions[0]?.latest_invoice_data?.invoice_date
                        ),
                        new Date()
                      );
                    }
                    return (
                      <tr
                        className={
                          (item.deleted_at ? "deletedRow" : "") + " table-row"
                        }
                        key={`dealer-${item.id}`}
                      >
                        <td className="w-200 d-flex align-items-center ps-4">
                          <div className="profile-flag">
                            <div className="profileImage">
                              <img
                                src={item.logo_url ? item.logo_url : profilePic}
                              />
                            </div>
                            {item.limited_address !== null &&
                            item.limited_address !== undefined ? (
                              <img
                                className="countryflag"
                                src={item.limited_address.flag_thumbnail}
                                title={item.limited_address.country}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="d-flex flex-column align-items-start">
                            {item.deleted_at === null ? (
                              <NavLink
                                to={
                                  `/company-details/${item.id}?` +
                                  (status !== ""
                                    ? "status=" + status + "&"
                                    : "") +
                                  (hotdealDiscount !== ""
                                    ? "hotdealDiscount=" + hotdealDiscount + "&"
                                    : "") +
                                  (country !== ""
                                    ? "country_id=" + country + "&"
                                    : "") +
                                  (sort !== "created_at"
                                    ? "sort=" + sort + "&"
                                    : "") +
                                  (startDate !== ""
                                    ? "start_plan=" + startDate + "&"
                                    : "") +
                                  (endDate !== ""
                                    ? "end_plan=" + endDate + "&"
                                    : "") +
                                  (searchkeyword !== ""
                                    ? "name=" + searchkeyword + "&"
                                    : "") +
                                  (vat !== "" ? "vat=" + vat + "&" : "") +
                                  (countryCode !== ""
                                    ? "country_code=" + countryCode + "&"
                                    : "") +
                                  (trashed !== true
                                    ? "trashed=" + trashed + "&"
                                    : "") +
                                  (countValue !== ""
                                    ? "per_page=" + countValue + "&"
                                    : "") +
                                  (page !== 1 ? "page=" + page : "")
                                }
                                className="link"
                                title={
                                  item.name !== null
                                    ? item.name
                                        .replace("_", " ")
                                        .charAt(0)
                                        .toUpperCase() +
                                      item.name.replace("_", " ").slice(1)
                                    : ""
                                }
                              >
                                <span
                                  className="name-text"
                                  title={
                                    item.name !== null
                                      ? item.name
                                          .replace("_", " ")
                                          .charAt(0)
                                          .toUpperCase() +
                                        item.name.replace("_", " ").slice(1)
                                      : "-"
                                  }
                                >
                                  {item.name !== null
                                    ? item.name
                                        .replace("_", " ")
                                        .charAt(0)
                                        .toUpperCase() +
                                      item.name.replace("_", " ").slice(1)
                                    : "-"}
                                </span>
                              </NavLink>
                            ) : (
                              <span
                                className="name-text disabled"
                                title={
                                  item.name.charAt(0).toUpperCase() +
                                  item.name.slice(1)
                                }
                              >
                                {" "}
                                {item.name.charAt(0).toUpperCase() +
                                  item.name.slice(1)}
                              </span>
                            )}
                            {item?.parent_company?.user?.id ? (
                              <a
                                target="_blank"
                                href={`detail-member/${item.parent_company.user.id}?verify_id=VerifyId&pendingTab=verification&`}
                              >
                                <span>
                                  {item.parent_company.user?.first_name || ""}{" "}
                                  {item.parent_company.user?.last_name || ""}
                                </span>
                              </a>
                            ) : (
                              <span>
                                {item?.parent_company?.user?.first_name || ""}{" "}
                                {item?.parent_company?.user?.last_name || ""}
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="w120">
                          <span
                            className={
                              (item.active === 1
                                ? "active-status"
                                : "end-status") + " d-block"
                            }
                          >
                            {item.active === 1 ? "Active" : "Inactive"}
                          </span>
                          {item.subscriptions.length > 0 &&
                          item.subscriptions[0].ends_at !== null &&
                          item.subscriptions[0].stripe_status === "active" ? (
                            <p className="registration-end-status d-block">
                              Cancelling
                            </p>
                          ) : (
                            <p
                              className={
                                SUBSIDIARY[
                                  item.subscriptions[0]?.stripe_status
                                ] + "  d-block"
                              }
                            >
                              {item.subscriptions.length > 0 &&
                              item.subscriptions[0].stripe_status
                                ? item.subscriptions[0].stripe_status
                                    .replace("_", " ")
                                    .charAt(0)
                                    .toUpperCase() +
                                  item.subscriptions[0].stripe_status
                                    .replace("_", " ")
                                    .slice(1)
                                : "-"}
                              {item.subscriptions.length > 0 &&
                              item.subscriptions[0].stripe_status ===
                                "past_due" &&
                              diffDays > 0 ? (
                                <span> ({diffDays} days)</span>
                              ) : null}
                            </p>
                          )}
                          <span className="d-block">
                            {item.parent_company !== null
                              ? item.parent_company.prefix_vat +
                                " " +
                                item.parent_company.vat_number
                              : ""}
                          </span>
                        </td>
                        <td className="w120">
                          <span className="d-block">
                            {item.subscriptions !== null &&
                            item.subscriptions.length > 0
                              ? item.subscriptions[0]?.items[0].price !== null
                                ? item.subscriptions[0]?.items[0].price.plan
                                    .name
                                : "-"
                              : "-"}
                          </span>
                          <span className="block">
                            {item.subscriptions !== null &&
                            item.subscriptions.length > 0
                              ? item.subscriptions[0]?.items[0].price !== null
                                ? formatCurrency(
                                    item.subscriptions[0]?.items[0].price
                                      .unit_amount
                                  ) +
                                  " " +
                                  item.subscriptions[0]?.items[0].price
                                    .recurring_interval +
                                  "ly"
                                : "-"
                              : "-"}
                          </span>
                        </td>
                        <td className="time-start">
                          <span className="d-block">
                            {item.created_at !== null
                              ? moment(new Date(item.created_at * 1000))
                                  .tz("Europe/Amsterdam")
                                  .format("DD-MM-YYYY HH:mm")
                              : "-"}
                          </span>
                          <span className="block">
                            {item.deleted_at !== null
                              ? moment(item.deleted_at * 1000)
                                  .tz("Europe/Amsterdam")
                                  .format("DD-MM-YYYY HH:mm")
                              : "-"}
                          </span>
                        </td>
                        <td className="w120">
                          <span className="d-block">
                            {item.subscriptions !== null &&
                            item.subscriptions.length > 0
                              ? item.subscriptions[0].start_date !== null
                                ? moment(item.subscriptions[0].start_date)
                                    .tz("Europe/Amsterdam")
                                    .format("DD-MM-YYYY HH:mm")
                                : "-"
                              : "-"}
                          </span>
                          <span className="block">
                            {item.subscriptions !== null &&
                            item.subscriptions.length > 0
                              ? item.subscriptions[0].ends_at !== null
                                ? moment(item.subscriptions[0].ends_at)
                                    .tz("Europe/Amsterdam")
                                    .format("DD-MM-YYYY HH:mm")
                                : "-"
                              : "-"}
                          </span>
                        </td>
                        <td className="time-start d-flex flex-column">
                          {item.ads_active}
                        </td>
                        <td className="time-start d-flex flex-column">
                          {item.total_ads}
                        </td>
                        <td className="time-start">
                          <span className="d-block">{item.vehicle_bought}</span>
                          <span className="block">{item.vehicle_sold}</span>
                        </td>
                        <td className="time-start d-flex flex-column">
                          {item.ads_in_auction}
                        </td>
                        <td className="time-start d-flex flex-column">
                          {item.members}
                        </td>
                        {/* <td className="w80">-</td> */}
                        <td className="w100">{item.sanctions}</td>
                        <td className="w100">
                          {item.deleted_at === null ? (
                            <input
                              key={`${item.id}_${item.hotdeal_discount}`}
                              type="checkbox"
                              checked={
                                item.hotdeal_discount ===
                                hotdealDiscountType.applied
                              }
                              onChange={(e) =>
                                handleChangeHotDealDiscount(e, index, item.id)
                              }
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
            </table>
          </div>
          {totalPage > 1 ? (
            <Pagination
              totalPages={totalPage}
              key={totalPage}
              page={page}
              onPageClick={(page) => setPage(page + 1)}
            />
          ) : (
            ""
          )}
        </div>
      </CompanyWrapper>
    </>
  );
};

export default Company;
