import styled from "styled-components";

export const Wrapper = styled.div`
  .underMaintenance {
    font-family: "inter";
    height: 95vh;
    display: flex;
    margin: 0 20px; // To look nice on mobile
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h1 {
    font-size: 3em;
    font-weight: 100;
    color: #6579f4;
    margin: 0;
  }
  h2 {
    font-size: 1.5em;
    font-weight: 100;
    margin-bottom: 0;
  }
  .ctbLogo {
    margin-top: 20px;
  }
`;
