import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CreateAuctionWrapper } from "./style/createAuction";
import Switch from "react-switch";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast } from "react-toastify";
import moment, { now } from "moment";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import Datetime from "react-datetime";
import * as Constant from "../../../config/constant";
import alertIcon from "../../../assets/Images/icons/alertIcon.svg";
import { getTimeAfter_N_Hours } from "../../../core/utils/getTimeAfterN_Hours/getTimeAfterN_Hours";
import { calcTimeWith00SecFormat } from "../../../core/utils/getAmsterdamTime/getTime";
import { getAmstoBrowserTime } from "../../../core/utils/getAmstoBrowsertime/getAmstoBrowserTime";
import { getProductTypeName, N_hours_from_minutes } from "../../../core/utils";
import ChipList from "../../../assets/SharedComponents/SearchableDropdown/ChipList";

let title = {};
let description = {};

const EditAuction = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [checked, _checked] = useState(false);

  const [activeLanguages, _activeLanguages] = useState([]);
  const [languagesloaded, _languagesloaded] = useState(false);

  const [loading, _loading] = useState(false);
  const [countries, setcountries] = useState(
    JSON.parse(localStorage.getItem("countries"))
  );
  const [dropdownValues, _dropdownValues] = useState([]);

  const [dateAdsVal, _dateAdsVal] = useState(2000);

  //Preview Values
  const [titleValue, _titleValue] = useState("");
  const [product, _product] = useState("");
  const [buyerValue, _buyerValue] = useState("All");
  const [sellerValue, _sellerValue] = useState("All");

  const [startDateTimeValue, _startDateTimeValue] = useState(new Date());
  const [endDateTimeValue, _endDateTimeValue] = useState(new Date());
  const [registrationStartsAt, _registrationStartsAt] = useState(new Date());
  const [countryflag, _countryflag] = useState("");
  const [selectedValuesData, _selectedValuesData] = useState([]);
  const today = new Date(getAmstoBrowserTime());
  const yesterday = new Date().setDate(new Date().getDate() - 1);

  const { auctionEventId } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    getActiveLanguages();
    setDropdownValues();
    let languages = activeLanguages;
    let code = "";
    for (let i = 0; i < languages.length; i++) {
      code = languages[i].code;
      title[code] = "";
      description[code] = "";
    }
  }, []);

  useEffect(() => {
    if (languagesloaded === true) {
      getAuctionEventDetails();
    }
  }, [languagesloaded]);

  const getAuctionEventDetails = () => {
    API.get(APP_URLS.VIEW_AUCTION_EVENT + `/${auctionEventId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setValues(resp.data.auctionEvent);
          _selectedValuesData(() =>
            resp?.data?.auctionEvent?.auction_sellers?.map(
              (seller) => seller?.subsidiary?.name
            )
          );
          setTimeout(() => {
            formik.validateForm();
          });
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setValues = (data) => {
    // Setting field values
    Object.keys(data.title).map((val, index) => {
      let elem = document.getElementById(val);
      if (elem !== null) {
        elem.value = data.title[val];
        title[val] = data.title[val];
      }
    });
    Object.keys(data.description).map((val, index) => {
      let elem = document.getElementById(val + "desc");
      if (elem !== null) {
        elem.value = data.description[val];
        description[val] = data.description[val];
      }
    });
    document.getElementById("startDate").value = moment(data.first_start_event)
      .tz("Europe/Amsterdam")
      .format("DD-MM-YYYY HH:mm");
    document.getElementById("endDate").value = moment(data.first_end_event)
      .tz("Europe/Amsterdam")
      .format("DD-MM-YYYY HH:mm");

    // convert hours into date , add hours into auction start date  to get the auctionEvent start date and auctionEvent End date.
    const startDate = moment(
      getTimeAfter_N_Hours(
        data.first_start_event,
        -N_hours_from_minutes(data.start_record_before_event)
      )
    );
    const startDateAdsValue = moment(startDate).tz("Europe/Amsterdam");
    formik.setFieldValue("startDateAds", startDateAdsValue);
    _registrationStartsAt(startDateAdsValue);
    const endDate = moment(
      getTimeAfter_N_Hours(
        data.first_start_event,
        -N_hours_from_minutes(data.end_record_before_event)
      )
    );
    const endDateAdsValue = moment(endDate).tz("Europe/Amsterdam");
    formik.setFieldValue("endDateAds", endDateAdsValue);

    formik.setFieldValue(
      "secondeExtension",
      data.second_extension < 10
        ? `0${data.second_extension}`
        : data.second_extension
    );
    formik.setFieldValue(
      "decisionTime",
      `${data.decision_time.split(":")[0]}:${data.decision_time.split(":")[1]}`
    );
    document.getElementById("recurring").value =
      Constant.RECURRING[data.recurring];
    document.getElementById("productType").value = getProductTypeName(
      data.product_category_type.product_type_id
    );
    document.getElementById("country").value = countries.find(
      (o) => o.id === data.country_id
    ).fullname;
    const timeIntervalValue = convertIntoTime(data.time_interval_ads);
    document.getElementById("timeIntervalAds").value = timeIntervalValue;
    document.getElementById(`buyer${data.display_for}`).checked = true;
    document.getElementById(`seller${data.seller_allowed}`).checked = true;
    if (data.seller_allowed === 1) {
      _sellerValue("Company");
      _checked(true);
      if (data.seller) {
        document.getElementById("subsidiary").value = data.seller.name;
      }
    }

    // Setting preview values
    _countryflag(
      countries.find((o) => o.id === data.country_id)?.flag_thumbnail
    );
    _product(getProductTypeName(data.product_category_type.product_type_id));
    if (data.display_for === 0) {
      _buyerValue("All");
    } else if (data.display_for === 1) {
      _buyerValue("Company");
    } else {
      _buyerValue("Private");
    }
    if (data.seller_allowed === 0) {
      _sellerValue("All");
    } else if (data.seller_allowed === 1) {
      _sellerValue("Company");
    } else {
      _sellerValue("Private");
    }
    _startDateTimeValue(
      calcTimeWith00SecFormat(
        data.first_start_event,
        `+${moment().tz("Europe/Amsterdam")._offset / 60}`
      )
    );
    _endDateTimeValue(
      calcTimeWith00SecFormat(
        data.first_end_event,
        `+${moment().tz("Europe/Amsterdam")._offset / 60}`
      )
    );
  };

  // convert seconds into (MM:SS) minutes:seconds
  const convertIntoTime = (totalSeconds) => {
    let minutes = Math.floor(totalSeconds / 60);
    let remainSeconds = totalSeconds % 60;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    remainSeconds = remainSeconds < 10 ? "0" + remainSeconds : remainSeconds;
    return minutes + ":" + remainSeconds;
  };

  const getActiveLanguages = () => {
    API.get(APP_URLS.LANGUAGE_LIST)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let languages = resp.data.languages;
          _activeLanguages(languages);
          _languagesloaded(true);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const setDropdownValues = () => {
    let values = [];
    for (let i = 0; i < 60; i++) {
      values.push(
        <option value={i < 10 ? `0${i}` : i} key={i}>
          {i < 10 ? `0${i}` : i}
        </option>
      );
    }
    _dropdownValues(values);
  };

  const handleTitle = (val, code) => {
    title[code] = val;
    if (code === "en") {
      _titleValue(val);
    }
  };

  const handleDesc = (val, code) => {
    description[code] = val;
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      startDateAds: "",
      endDateAds: "",
      secondeExtension: "",
      decisionTime: "",
      file: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
      description: Yup.string().required(),
      startDateAds: Yup.date()
        .required("Ads Insert Start Time can't be blank")
        .min(
          getTimeAfter_N_Hours(startDateTimeValue, -2000),
          "Please select a date at most 2000 hours before auction starts"
        )
        .max(
          getTimeAfter_N_Hours(startDateTimeValue, -2),
          "Please select a date at least 2 hours before auction starts"
        ),
      endDateAds: Yup.date()
        .required("End insert Date Ads can't be blank")
        .min(
          getTimeAfter_N_Hours(registrationStartsAt, 1),
          "Please select a date greater than ad starts in ."
        )
        .max(
          getTimeAfter_N_Hours(startDateTimeValue, -N_hours_from_minutes(1)),
          "Maximum value should be less than Auction start date"
        ),
      secondeExtension: Yup.number()
        .required("Extension Period can't be blank")
        .min(0, "Value can't be negative")
        .max(59, "Maximum value should be 59"),
      decisionTime: Yup.string()
        .required("Decision Time can't be blank")
        .matches(/^(?:[0-9][0-9]|59):[0-5][0-9]$/, "Time is not correct"),
      file: "",
    }),
    onSubmit: (values) => {
      let data = {};
      data["title"] = title;
      data["description"] = description;
      data["start_record_before_event"] = Math.floor(
        (new Date(startDateTimeValue) - new Date(values.startDateAds)) / 60000
      ); // convert date into hours
      data["end_record_before_event"] = Math.floor(
        (new Date(startDateTimeValue) - new Date(values.endDateAds)) / 60000
      );
      data["second_extension"] = values.secondeExtension;
      data["decision_time"] = values.decisionTime;
      if (!loading) {
        _loading(true);
        API.post(
          APP_URLS.UPDATE_AUCTION_EVENT +
            `/${auctionEventId}/update?_method=PATCH`,
          data
        )
          .then((res) => {
            const resp = res.data;
            _loading(false);
            if (resp.success === true) {
              let respID = resp.data.auctionEvent.id;
              toast.success("Auction has been updated successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate(
                "/auction-events?" +
                  (searchParams.get("ae_country_id") !== null
                    ? "ae_country_id=" + searchParams.get("ae_country_id") + "&"
                    : "") +
                  (searchParams.get("ae_seller_allowed") !== null
                    ? "ae_seller_allowed=" +
                      searchParams.get("ae_seller_allowed") +
                      "&"
                    : "") +
                  (searchParams.get("ae_display_for") !== null
                    ? "ae_display_for=" +
                      searchParams.get("ae_display_for") +
                      "&"
                    : "") +
                  (searchParams.get("ae_product_type_id") !== null
                    ? "ae_product_type_id=" +
                      searchParams.get("ae_product_type_id") +
                      "&"
                    : "") +
                  (searchParams.get("ae_recurring") !== null
                    ? "ae_recurring=" + searchParams.get("ae_recurring") + "&"
                    : "") +
                  (searchParams.get("ae_keyword") !== null
                    ? "ae_keyword=" + searchParams.get("ae_keyword") + "&"
                    : "") +
                  (searchParams.get("ae_per_page") !== null
                    ? "ae_per_page=" + searchParams.get("ae_per_page") + "&"
                    : "") +
                  (searchParams.get("ae_start_day") !== null
                    ? "ae_start_day=" + searchParams.get("ae_start_day") + "&"
                    : "") +
                  (searchParams.get("ae_end_day") !== null
                    ? "ae_end_day=" + searchParams.get("ae_end_day") + "&"
                    : "") +
                  (searchParams.get("ae_page") !== null
                    ? "ae_page=" + searchParams.get("ae_page")
                    : "")
              );
            } else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(function (error) {
            const resp = error.response;
            _loading(false);
            let error_message = "";
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) => (error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error;
            } else {
              error_message = resp.data.message;
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    },
  });

  const submitForm = (e) => {
    let languages = activeLanguages;
    let code = "";
    for (let i = 0; i < languages.length; i++) {
      code = languages[i].code;
      if (title[code] != "" && description[code] != "") {
        formik.setFieldValue("title", JSON.stringify(title));
        formik.setFieldValue("description", JSON.stringify(description));
        setTimeout(() => formik.handleSubmit(e), 100);
        break;
      } else if (i === languages.length - 1) {
        formik.handleSubmit(e);
      }
    }
  };

  const convertIntoHours = (date) => {
    const diff = moment(date) - now();
    _dateAdsVal(Math.floor(diff / 3600 / 1000));
  };
  return (
    <>
      <CreateAuctionWrapper>
        <div className="mt-2">
          <div
            className="backContainer ms-4"
            onClick={() =>
              navigate(
                "/auction-events?" +
                  (searchParams.get("ae_country_id") !== null
                    ? "ae_country_id=" + searchParams.get("ae_country_id") + "&"
                    : "") +
                  (searchParams.get("ae_seller_allowed") !== null
                    ? "ae_seller_allowed=" +
                      searchParams.get("ae_seller_allowed") +
                      "&"
                    : "") +
                  (searchParams.get("ae_display_for") !== null
                    ? "ae_display_for=" +
                      searchParams.get("ae_display_for") +
                      "&"
                    : "") +
                  (searchParams.get("ae_product_type_id") !== null
                    ? "ae_product_type_id=" +
                      searchParams.get("ae_product_type_id") +
                      "&"
                    : "") +
                  (searchParams.get("ae_recurring") !== null
                    ? "ae_recurring=" + searchParams.get("ae_recurring") + "&"
                    : "") +
                  (searchParams.get("ae_keyword") !== null
                    ? "ae_keyword=" + searchParams.get("ae_keyword") + "&"
                    : "") +
                  (searchParams.get("ae_per_page") !== null
                    ? "ae_per_page=" + searchParams.get("ae_per_page") + "&"
                    : "") +
                  (searchParams.get("ae_start_day") !== null
                    ? "ae_start_day=" + searchParams.get("ae_start_day") + "&"
                    : "") +
                  (searchParams.get("ae_end_day") !== null
                    ? "ae_end_day=" + searchParams.get("ae_end_day") + "&"
                    : "") +
                  (searchParams.get("ae_page") !== null
                    ? "ae_page=" + searchParams.get("ae_page")
                    : "")
              )
            }
          >
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <form
          className="auctionMainContainer"
          onSubmit={(e) => {
            submitForm(e);
            e.preventDefault();
          }}
        >
          <div className="headerContainer mb-2">
            <div></div>
            <h1>EDIT AUCTION</h1>
            <div className="actionContainer">
              <button type="submit" className="btn">
                {" "}
                {loading ? "Saving..." : "Save"}{" "}
              </button>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <label className="fieldLabel mb-2">Title</label>
              {activeLanguages.map((item, index) => {
                let flag = countries.find(
                  (o) => o.id === item.country_id
                )?.flag_thumbnail;
                let itemCode = item.code;
                return (
                  <div className="col-lg-3 col-md-6 auction-form" key={index}>
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        <span className="px-2">
                          <img
                            className="countryflag"
                            src={flag}
                            title={item.name}
                          />
                        </span>
                        <span className="langName">{itemCode}</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control langaugeFields"
                        id={item.code}
                        name={item.code}
                        onChange={(e, code = itemCode) => {
                          handleTitle(e.target.value, code);
                        }}
                        placeholder=""
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <label className="fieldLabel mt-3 mb-2">Description</label>
              {activeLanguages.map((item, index) => {
                let flag = countries.find(
                  (o) => o.id === item.country_id
                )?.flag_thumbnail;
                let itemCode = item.code;
                return (
                  <div className="col-lg-3 col-md-6 auction-form" key={index}>
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        <span className="px-2">
                          <img
                            className="countryflag"
                            src={flag}
                            title={item.name}
                          />
                        </span>
                        <span className="langName">{itemCode}</span>{" "}
                      </label>
                      <textarea
                        className="form-control langaugeFields"
                        id={item.code + "desc"}
                        name={item.code + "desc"}
                        rows="1"
                        onChange={(e, code = itemCode) => {
                          handleDesc(e.target.value, code);
                        }}
                      ></textarea>
                    </div>
                  </div>
                );
              })}
              {formik.touched.title &&
              formik.errors.title &&
              formik.touched.description &&
              formik.errors.description ? (
                <div className="error">
                  Atleast one title and its description is required.
                </div>
              ) : null}
            </div>
            <div className="row mt-4">
              <div className="col-lg-6 col-sm-12">
                <div className="auction-form">
                  <div className="row">
                    <div className="col-lg-4 form-group">
                      <label htmlFor="startDate" className="form-label">
                        Auction Start Date
                      </label>
                      <input
                        type="text"
                        name="startDate"
                        id="startDate"
                        className="form-control disabled"
                        disabled
                      />
                    </div>
                    <div className="col-lg-4 form-group">
                      <label htmlFor="endDate" className="form-label">
                        Auction End Date
                      </label>
                      <input
                        type="text"
                        name="endDate"
                        id="endDate"
                        className="form-control disabled"
                        disabled
                      />
                    </div>
                    <div className="col-lg-4 form-group">
                      <label htmlFor="recurring" className="form-label">
                        Frequency
                      </label>
                      <input
                        type="text"
                        className="form-control disabled"
                        name="recurring"
                        id="recurring"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 form-group">
                      <label htmlFor="startDateAds" className="form-label">
                        Ads Insert Start Time (H)
                      </label>
                      <Datetime
                        name="startDateAds"
                        id="startDateAds"
                        dateFormat="DD-MM-YYYY"
                        value={formik.values.startDateAds}
                        inputProps={{
                          readOnly: true,
                          placeholder: "Select a date and time",
                        }}
                        isValidDate={(date) => {
                          return date.isAfter(yesterday);
                        }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "startDateAds",
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                          convertIntoHours(
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                          _registrationStartsAt(
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.startDateAds ? (
                        <div className="error">
                          {formik.errors.startDateAds}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 form-group">
                      <label htmlFor="endDateAds" className="form-label">
                        End Insert Time Ads
                      </label>
                      <Datetime
                        name="endDateAds"
                        id="endDateAds"
                        dateFormat="DD-MM-YYYY"
                        value={formik.values.endDateAds}
                        inputProps={{
                          readOnly: true,
                          placeholder: "Select a date and time",
                        }}
                        isValidDate={(date) => {
                          return date.isAfter(yesterday);
                        }}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "endDateAds",
                            calcTimeWith00SecFormat(
                              e,
                              `+${moment().tz("Europe/Amsterdam")._offset / 60}`
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.endDateAds ? (
                        <div className="error">{formik.errors.endDateAds}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 form-group">
                      <label htmlFor="secondeExtension" className="form-label">
                        Extension Period (S)
                      </label>
                      <select
                        name="secondeExtension"
                        id="secondeExtension"
                        value={formik.values.secondeExtension}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      >
                        <option hidden>Select</option>
                        {dropdownValues}
                      </select>
                      {formik.touched.secondeExtension &&
                      formik.errors.secondeExtension ? (
                        <div className="error">
                          {formik.errors.secondeExtension}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label htmlFor="productType" className="form-label">
                        Product Type
                      </label>
                      <input
                        type="text"
                        name="productType"
                        id="productType"
                        className="form-control disabled"
                        disabled
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <input
                        type="text"
                        name="country"
                        id="country"
                        className="form-control disabled"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label htmlFor="decisionTime" className="form-label">
                        Decision Time (HH:MM)
                        <img
                          src={alertIcon}
                          className="tooltipIcon"
                          title="Seller has to accept or reject bids in between this time period when auction ends."
                        />
                      </label>
                      <input
                        type="text"
                        name="decisionTime"
                        id="decisionTime"
                        placeholder="00:00"
                        className="form-control"
                        value={formik.values.decisionTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.decisionTime &&
                      formik.errors.decisionTime ? (
                        <div className="error">
                          {formik.errors.decisionTime}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="timeIntervalAds" className="form-label">
                        Time Interval Ads (MM:SS)
                      </label>
                      <input
                        type="text"
                        name="timeIntervalAds"
                        id="timeIntervalAds"
                        className="form-control disabled"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="formInput mb-4">
                    <label htmlFor="seller" className="form-label mb-3">
                      <b>Type Buyer</b>
                    </label>
                    <div className="radioOptions ps-5">
                      <div className="options">
                        <input
                          type="radio"
                          id="buyer0"
                          name="buyer"
                          value="0"
                          disabled
                        />
                        <label htmlFor="html">All</label>
                      </div>
                      <div className="options">
                        <input
                          type="radio"
                          id="buyer1"
                          name="buyer"
                          value="1"
                          disabled
                        />
                        <label htmlFor="css">
                          {Constant.userTypeName.DEALER}
                        </label>
                      </div>
                      <div className="options">
                        <input
                          type="radio"
                          id="buyer2"
                          name="buyer"
                          value="2"
                          disabled
                        />
                        <label htmlFor="css">
                          {Constant.userTypeName.PRIVATE}
                        </label>
                      </div>
                    </div>
                    {formik.touched.buyer && formik.errors.buyer ? (
                      <div className="error">{formik.errors.buyer}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-1">
                <div className="v1"></div>
              </div>

              <div className="col-lg-5 com-sm-12">
                <div className="rightFormInputContainer">
                  <div className="preview">
                    <label className="form-label">
                      <b>Preview</b>
                    </label>
                    <div className="previewCard">
                      <div className="previewCardContainer">
                        <div className="cardHeader">
                          <div className="cardContent">
                            <div className="cardContentHeader">
                              <p>
                                {title["en"] !== ""
                                  ? title["en"]
                                  : "Auction Title"}
                              </p>
                            </div>
                            <div className="cardContentContainer">
                              <div className="cardContentText">
                                <p className="cardContentTextHead">
                                  Allowed Product :
                                </p>
                                <p className="cardContentTextContent">
                                  {" "}
                                  {product}
                                </p>
                                <p></p>
                              </div>
                              <div className="cardContentText">
                                <p className="cardContentTextHead">
                                  Allowed Buyer :
                                </p>
                                <p className="cardContentTextContent">
                                  {" "}
                                  {buyerValue}
                                </p>
                                <p></p>
                              </div>
                              <div className="cardContentText">
                                <p className="cardContentTextHead">
                                  Allowed Seller :
                                </p>
                                <p className="cardContentTextContent me-2">
                                  {" "}
                                  {sellerValue}
                                </p>
                                <div className="cardContentTextContent">
                                  <p>From</p>
                                </div>
                                {countryflag ? (
                                  <span className="ps-3">
                                    <img
                                      src={countryflag}
                                      className="countryflag"
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <hr />
                        </div>
                        <div className="cardFooter">
                          <p className="cardFooterHeading">
                            Start Date :
                            <span className="cardFooterContent">
                              {" " +
                                moment(startDateTimeValue).format(
                                  "DD-MM-YYYY HH:MM"
                                )}
                            </span>
                          </p>
                          <p className="cardFooterHeading">
                            End Date
                            <span className="cardFooterContent">
                              {" " +
                                moment(endDateTimeValue).format(
                                  "DD-MM-YYYY HH:MM"
                                )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-5" />
                  <div className="formInput mb-4">
                    <label htmlFor="seller" className="form-label mb-3">
                      <b>Type Seller</b>
                    </label>
                    <div className="radioOptions ps-5">
                      <div className="options">
                        <input
                          type="radio"
                          id="seller0"
                          name="seller"
                          value="0"
                          disabled
                        />
                        <label htmlFor="html">All</label>
                      </div>
                      <div className="options">
                        <input
                          type="radio"
                          id="seller1"
                          name="seller"
                          value="1"
                          disabled
                        />
                        <label htmlFor="css">
                          {Constant.userTypeName.DEALER}
                        </label>
                      </div>
                      <div className="options">
                        <input
                          type="radio"
                          id="seller2"
                          name="seller"
                          value="2"
                          disabled
                        />
                        <label htmlFor="javascript">Private</label>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      (sellerValue === "Company" ? "d-block" : "d-none") +
                      " formInput auction-form"
                    }
                  >
                    <ChipList chips={selectedValuesData} disabled={true} />
                    <div className="inputSwitchgroup mt-3 mb-5 ps-5 d-flex justify-content-between flex-wrap">
                      <div className="leftSwitchChild mb-2">
                        <Switch
                          checked={checked}
                          className="react-switch"
                          id="react-switch"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          offColor="#D9D9D9"
                          onColor="#7589EC"
                          disabled
                        />
                        <p>{`Exclusive Seller ${Constant.userTypeName.DEALER}`}</p>
                      </div>
                      <div className="rightSwitchChild resultSearchOuter">
                        <input
                          type="text"
                          name="subsidiary"
                          placeholder={`Exclusive Seller ${Constant.userTypeName.DEALER}`}
                          id="subsidiary"
                          className="form-control disabled"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </CreateAuctionWrapper>
    </>
  );
};

export default EditAuction;
