import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import SearchKey from "../../../../assets/SharedComponents/Search/SearchKey";
import attach from "../../../../assets/Images/icons/svgIcon/Attach.svg";
import { FaFilterIcon } from "../../../../assets/Images/icon";
import Header from "./Header";
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from "react-toastify";
import RecordCount from "../../../../assets/SharedComponents/RecordCount/RecordCount";
import NoRecord from "../../../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../../../assets/SharedComponents/Pagination";
import moment from "moment";
import * as Constant from "../../../../config/constant";
import download from "downloadjs";
import { getSum } from "../../../../core/utils/getSum/getSum";
import StaticFilter from "../../../../assets/SharedComponents/Filters/StaticFilter";
import { useLocation, useSearchParams } from "react-router-dom";
import CountryFilter from "../../../../assets/SharedComponents/Filters/CountryFilter";
import Popup from "./Popup";
import error from "../../../../assets/Images/icons/svgIcon/Error.svg";
import {
  constructQueryStringFromIds,
  formatCurrency,
} from "../../../../core/utils";

const invoiceStatusOptionsList = Object.keys(Constant.paymentStatusOptions).map(
  (key) => ({ name: key, value: Constant.paymentStatusOptions[key] })
);

const CommissionTable = ({
  nomads,
  taxRates,
  tabView,
  setTabView,
  showFilters,
  _showFilters,
  _action,
  action,
}) => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const day = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    .toJSON()
    .slice(0, 10);

  const [data, _data] = useState([]);
  const [calData, _calData] = useState("");
  const [count, _count] = useState(0);
  const [searchkeyword, _searchkeyword] = useState("");
  const [searchInvoicekeyword, _searchInvoicekeyword] = useState("");
  const [status, _status] = useState("");
  const [trashed, _trashed] = useState(true);
  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, _totalPage] = useState(0);
  const [countValue, _countValue] = useState("");

  //SideBarFilters
  const [country, _country] = useState("");
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  });
  const [popup, _popup] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [allInvoices, _allInvoices] = useState(false);

  useEffect(() => {
    getVehicleCommission();
  }, [
    page,
    countValue,
    searchkeyword,
    status,
    dateRange,
    country,
    searchInvoicekeyword,
    trashed,
  ]);

  function getRestUrl() {
    let urlParams = {};

    if (page) urlParams["page"] = page;
    if (countValue) urlParams["per_page"] = countValue;
    if (searchkeyword) urlParams["keyword"] = searchkeyword;
    if (status) urlParams["filter[status]"] = status;
    if (dateRange && dateRange.start)
      urlParams["filter[vehicle_sales_invoice_plan_start_date]"] =
        dateRange.start;
    if (dateRange && dateRange.end)
      urlParams["filter[vehicle_sales_invoice_plan_end_date]"] = dateRange.end;
    if (country) urlParams["filter[country_id]"] = country;
    if (searchInvoicekeyword)
      urlParams["filter[invoice_no]"] = searchInvoicekeyword;
    if (trashed !== undefined) urlParams["with_trashed"] = trashed ? 1 : 0;

    const restUrl = new URLSearchParams(urlParams);
    return restUrl.toString();
  }

  //fetching invoice listing for credits
  const getVehicleCommission = () => {
    const apiParams = getRestUrl();
    _loaded(true);
    API.get(`${APP_URLS.LIST_VEHICLE_COMMISSION}?${apiParams}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.vehicle_sales_invoice.data);
          _totalPage(resp.data.vehicle_sales_invoice.last_page);
          _calData(resp.data.total_amount);
          _count(resp.data.vehicle_sales_invoice.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //downloading invoice for credit
  const getAttachment = (url) => {
    API.get(url, { responseType: "blob" })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          download(resp, `invoice.pdf`, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        if (error.response) {
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  //handles common search functionality for search by name, invoice and per_page
  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (searchValue !== "") {
      _totalPage(0);
      setPage(1);
    }
  };

  //reset filter
  const clearFilter = () => {
    _filters(true);
    _status("");
    _country("");
    _trashed(true);
    handleSearchChange(_countValue, "");
    handleSearchChange(_searchkeyword, "");
    handleSearchChange(_searchInvoicekeyword, "");
    setDateRange({
      start: "",
      end: "",
    });
    if (
      searchkeyword !== "" ||
      status !== "" ||
      searchInvoicekeyword !== "" ||
      dateRange.start !== "" ||
      dateRange.end !== "" ||
      trashed !== true
    ) {
      _totalPage(0);
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  //getting flag url according to user type
  function getFlagURL(data) {
    if (
      data.subsidiary &&
      data.subsidiary.limited_address &&
      data.subsidiary.limited_address.flag_thumbnail
    ) {
      return data.subsidiary.limited_address.flag_thumbnail;
    } else if (
      data.user &&
      data.user.limited_address &&
      data.user.limited_address.flag_thumbnail
    ) {
      return data.user.limited_address.flag_thumbnail;
    }
    return null; // return null or a default value if flag URL is not found
  }

  //capitalize function for names
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  //geeting names according to user type
  function getName(data) {
    if (data.subsidiary && data.subsidiary.name) {
      return capitalizeFirstLetter(data.subsidiary.name);
    } else if (data.user && (data.user.first_name || data.user.last_name)) {
      const fullName = `${data.user.first_name} ${data.user.last_name}`.trim();
      return capitalizeFirstLetter(fullName);
    } else if (data.user && data.user.name) {
      return capitalizeFirstLetter(data.user.name);
    }
    return "-";
  }

  //country filter
  const setChangeValue = (item) => {
    _country(item);
    _totalPage(0);
    setPage(1);
  };
  //to download excel extract report for this page
  const handleExtractReport = () => {
    const apiurl = getRestUrl();
    API.get(
      `${APP_URLS.LIST_VEHICLE_COMMISSION}/download_excel_file?${apiurl}`,
      { responseType: "blob" }
    )
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_vehicle_sales_invoices_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //to download zip file for invoices
  const handleDownloadInvoiceZip = () => {
    if (selectedIds.length === 0) {
      toast.error("Please select at least one invoice to download.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    //constructing params for multiple invoices based on id's
    const selectedIdsParams = constructQueryStringFromIds(selectedIds, "id");

    API.get(
      `${APP_URLS.LIST_VEHICLE_COMMISSION}/download_zip_file?${selectedIdsParams}`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message || "Something went wrong";
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //handles particular selecting checkbox for invoice download in zip format
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((item) => item !== id)
        : [...prevIds, id]
    );
  };

  //handles selecting all checkbox for invoice download in zip format
  const handleSelectAll = () => {
    if (allInvoices) {
      setSelectedIds([]);
    } else {
      setSelectedIds(data.map((item) => item.id));
    }
    _allInvoices(!allInvoices);
  };

  return (
    <div className="auctionMainContainer">
      <Popup popup={popup} _popup={_popup} />
      <div className="row mt-4 position-relative">
        <div
          className={
            showFilters === true ? "col-md-9 col-lg-12" : "col-md-12 col-lg-12"
          }
        >
          <Header
            tabView={tabView}
            setTabView={setTabView}
            action={action}
            _action={_action}
            handleDownloadInvoiceZip={handleDownloadInvoiceZip}
          />
          <div className="col-md-12 mt-2">
            <div className="d-flex align-items-center justify-content-end flex-wrap">
              <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
                <div className="d-flex date-range-container gap-2">
                  <input
                    type="text"
                    className="date date-range-container"
                    id="start_date"
                    placeholder="Start Date"
                    onFocus={() =>
                      (document.getElementById("start_date").type = "date")
                    }
                    onBlur={() =>
                      dateRange.start === ""
                        ? (document.getElementById("start_date").type = "text")
                        : ""
                    }
                    value={dateRange.start}
                    max={dateRange.end ? dateRange.end : day}
                    onChange={(e) => {
                      setDateRange((prev) => ({
                        ...prev,
                        start: e.target.value,
                      }));
                      setPage(1);
                      _totalPage(0);
                    }}
                  />
                  <input
                    type="text"
                    className="date date-range-container"
                    id="end_date"
                    placeholder="End Date"
                    onFocus={() =>
                      (document.getElementById("end_date").type = "date")
                    }
                    onBlur={() =>
                      dateRange.end === ""
                        ? (document.getElementById("end_date").type = "text")
                        : ""
                    }
                    value={dateRange.end}
                    max={day}
                    onChange={(e) => {
                      setDateRange((prev) => ({
                        ...prev,
                        end: e.target.value,
                      }));
                      setPage(1);
                      _totalPage(0);
                    }}
                  />
                </div>
                <div style={{ width: "250px" }}>
                  <CountryFilter
                    value={country}
                    handleChange={(val) => setChangeValue(val)}
                  />
                </div>
                <StaticFilter
                  placeholder="Invoice Status"
                  data={invoiceStatusOptionsList}
                  handleChange={(e) => {
                    _status(e.target.value);
                    _totalPage(0);
                    setPage(1);
                  }}
                  value={status}
                />
                <SearchKey
                  onSearchClick={(search) =>
                    handleSearchChange(_searchInvoicekeyword, search)
                  }
                  id="searchData"
                  filters={filters}
                  _filters={_filters}
                  placeholder="Search by Invoice Number"
                />
                <SearchKey
                  onSearchClick={(search) =>
                    handleSearchChange(_searchkeyword, search)
                  }
                  id="searchData"
                  filters={filters}
                  _filters={_filters}
                  placeholder={`Search by Name`}
                />
                <RecordCount
                  onSearchClick={(search) =>
                    handleSearchChange(_countValue, search)
                  }
                  id="pageCount"
                  filters={filters}
                  _filters={_filters}
                />
                <Button
                  onClick={handleExtractReport}
                  className="extract-report ml-2"
                  variant="outlined"
                >
                  Extract report
                </Button>
                <div className="d-flex align-items-center">
                  <Tooltip title="Clear Filter" placement="bottom">
                    <FaFilterIcon
                      className="filter-icon"
                      role="button"
                      onClick={clearFilter}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
              <div className="justify-content-end mt-1 me-2">
                <span className="text-start">
                  Total Records : <b>{count}</b>
                </span>
              </div>
              <div className="d-flex gap-3">
                {action && (
                  <div className="mt-1">
                    <input
                      type="checkbox"
                      checked={allInvoices}
                      indeterminate={
                        selectedIds.length > 0 &&
                        selectedIds.length < data.length
                      }
                      onChange={handleSelectAll}
                    />
                    <span className="ms-2">Select all invoices</span>
                  </div>
                )}
                <div className="mt-1">
                  <input
                    type="checkbox"
                    checked={!trashed}
                    onChange={() => {
                      _totalPage(0);
                      setPage(1);
                      _trashed(!trashed);
                    }}
                  />
                  <span className="ms-2">Hide Deleted Data</span>
                </div>
              </div>
            </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th className="maxw-90">
                      ID
                      <br />
                      Country
                    </th>
                    <th className="time-start name-text">
                      Name <br />
                      Invoice Number
                    </th>
                    <th className="maxw-90">Invoice Status</th>
                    <th className="time-start">
                      Title
                      <br />
                    </th>
                    <th>
                      <span className="fieldTitle">(Invoice)</span>
                      <br />
                      Amount <br />
                      Date
                    </th>
                    <th>Paid at</th>
                    <th className="time-start">
                      Commission
                      <br />
                      VAT% <span className="fieldTitle">(VAT amount)</span>
                      <br />
                      Commission + VAT
                      <br />
                    </th>
                  </tr>
                </thead>
                {loaded ? (
                  <div className="d-flex justify-content-center three-dot">
                    <ThreeDots
                      height="150"
                      width="150"
                      radius="9"
                      color="#637df4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item) => {
                        return (
                          <tr
                            className={
                              (item.deleted_at ? "deletedRow" : "") +
                              " table-row"
                            }
                            key={item.id}
                          >
                            <td className="maxw-90">
                              <span className="d-block">{item.id} </span>
                              <span>
                                <img
                                  src={getFlagURL(item)}
                                  alt=""
                                  className="countryflag"
                                  title={
                                    item?.subsidiary?.limited_address
                                      ?.country ??
                                    item?.user?.limited_address?.country
                                  }
                                />
                              </span>
                            </td>
                            <td className="time-start name-text">
                              <span className="d-block" title={getName(item)}>
                                {getName(item)}
                              </span>
                              <span>
                                {item.invoice_no !== null
                                  ? item.invoice_no
                                  : ""}
                              </span>
                            </td>
                            <td className="maxw-90">
                              <div className="position-relative">
                                <span
                                  className={
                                    "status " +
                                    (item.status === 1
                                      ? "status-progress"
                                      : "status-active")
                                  }
                                  title={
                                    item.status === 1
                                      ? "Pending"
                                      : item.status === 2
                                      ? "Paid"
                                      : "-"
                                  }
                                ></span>
                                {item.error ? (
                                  <img
                                    src={error}
                                    className="errorIcon position-absolute"
                                    onClick={() => _popup(item.error)}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                            <td className="time-start">
                              <a
                                href={`${process.env.REACT_APP_FRONTOFFICE_URL_HTTPS}/en/hot-deal/${item.ad_vehicle?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={"link"}
                              >
                                {item.ad_vehicle?.owner_vehicle.full_title}
                              </a>
                            </td>
                            <td>
                              <div className="d-flex justify-content-evenly">
                                <div className="mw-120">
                                  <span
                                    className="block"
                                    title="Amount Without VAT"
                                  >
                                    {formatCurrency(item.subtotal)}
                                  </span>
                                  <span className="d-block text-nowrap">
                                    {moment(item.created_at)
                                      .tz("Europe/Amsterdam")
                                      .format("DD-MM-YYYY HH:mm")}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              {item.paid_at
                                ? moment(item.paid_at)
                                    .tz("Europe/Amsterdam")
                                    .format("DD-MM-YYYY HH:mm")
                                : "-"}
                            </td>
                            <td>
                              <div className="d-flex justify-content-evenly">
                                {action ? (
                                  <input
                                    type="checkbox"
                                    checked={selectedIds.includes(item.id)}
                                    onChange={() =>
                                      handleCheckboxChange(item.id)
                                    }
                                  />
                                ) : null}
                                <Tooltip
                                  title="Vehicle Sale Invoice"
                                  placement="right-end"
                                >
                                  <img
                                    className={
                                      item.vehicle_sale_invoice_url !== null
                                        ? "attachment"
                                        : "disabled"
                                    }
                                    src={attach}
                                    alt=""
                                    onClick={() =>
                                      getAttachment(
                                        item.vehicle_sale_invoice_url
                                      )
                                    }
                                  />
                                </Tooltip>
                                <div className="mw-120">
                                  <span
                                    className="block"
                                    title="Amount Without VAT"
                                  >
                                    €{" "}
                                    {formatCurrency(
                                      item?.payment_session
                                        ?.commission_amount ?? 0
                                    )}
                                  </span>
                                  <p className="d-block">
                                    {item?.tax_rate?.percentage}%
                                    <span className="light-text">
                                      (
                                      {item?.payment_session
                                        ?.commission_tax_amount !== null
                                        ? formatCurrency(
                                            item.payment_session
                                              .commission_tax_amount ?? 0
                                          )
                                        : "-"}
                                      )
                                    </span>
                                  </p>
                                  <span
                                    className="block"
                                    title="Amount Without VAT"
                                  >
                                    {formatCurrency(
                                      item.total_commission_amount
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecord />
                    )}
                    <tr className="table-row summary-row">
                      <td className="maxw-90"></td>
                      <td className="time-start name-text"></td>
                      <td className="maxw-90"></td>
                      <td></td>
                      <td className="time-start">
                        <b title="Total Amount Before Discount">
                          {calData !== ""
                            ? formatCurrency(calData?.subTotal)
                            : "-"}
                        </b>
                      </td>
                      <td></td>
                      <td>
                        <div className="d-flex flex-column">
                          <b title="Total Commission amount">
                            {calData !== ""
                              ? formatCurrency(calData?.totalCommissionAmount)
                              : "-"}
                          </b>
                          <b title="Total VAT amount">
                            {calData !== ""
                              ? formatCurrency(calData?.totalVatAmount)
                              : "-"}
                          </b>
                          <hr />
                          <b
                            className=""
                            title="Total Commission with VAT Amount"
                          >
                            {calData !== ""
                              ? "€ " +
                                getSum(
                                  calData?.totalCommissionAmount,
                                  calData?.totalVatAmount
                                )
                              : "-"}
                          </b>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
            <div>
              {totalPage > 1 ? (
                <Pagination
                  totalPages={totalPage}
                  page={page}
                  onPageClick={(page) => {
                    setPage(page + 1);
                  }}
                  key={totalPage}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionTable;
