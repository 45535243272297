import styled from "styled-components";

export const CompanyWrapper = styled.div`
  .auctionTable {
    min-width: 1520px;
  }
  .time-start svg {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }
  .mxw-90 {
    max-width: 90px;
  }
  .mxw-80 {
    max-width: 80px;
  }
  .opacity-40 {
    opacity: 0.4;
  }
  .headerContainer div,
  .headerContainer h1 {
    margin-bottom: 10px;
  }
  .searchContainer {
    display: flex;
    padding: 0 15px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .fieldTitle {
    font-size: 12px;
    font-style: italic;
    font-weight: 200;
    color: rgb(151, 151, 151);
  }
  .search {
    height: 35px;
    border: none;
    border-radius: 10px;
  }
  .info-wrap {
    flex-wrap: wrap;
  }
  .btn {
    align-self: center;
    background-color: #fbc524;
    border: none;
    height: 35px;
    border-radius: 5px;
    padding: 0 20px;
    color: white;
    font-weight: bold;
  }
  .select-info select {
    max-width: 170px;
  }
  .date {
    max-width: 140px;
  }
  .w-150 {
    width: 150px !important;
  }
  .w-200 {
    width: 200px !important;
  }
  .w100 {
    max-width: 100px !important;
  }
  .w120 {
    min-width: 120px;
  }
  .w80 {
    width: 80px;
  }
  .name-text {
    display: inline-block;
    width: 100px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: left;
  }
  .profile-flag {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .profileImage {
    height: 40px;
    width: 40px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  .profileImage img {
    margin: 0 auto;
    display: flex;
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  .countryflag {
    position: absolute;
    bottom: -12px;
    right: 6px;
  }
  .searchBox {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    padding: 0 15px 0 0;
  }
  .countryCode {
    width: 90px;
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-image: initial;
    background: white;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px 0px 0px 10px;
  }
  .vatField {
    max-width: 150px;
  }
  .searchBox img {
    cursor: pointer;
    width: 20px;
  }
  a {
    text-decoration: none;
    text-align: start;
  }
  .extract-report {
    font-weight: 600;
    cursor: pointer;
    text-transform: none;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 32px;
    &:hover {
      outline: none;
    }
  }
`;
