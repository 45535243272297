import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import back from "../../../assets/Images/icons/svgIcon/back-arrow.svg";
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { AuctionWrapper } from "./style/auction";
import noImage from "../../../assets/Images/image/noImage.svg";
import Pagination from "../../../assets/SharedComponents/Pagination";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import moment from "moment";
import * as Constant from "../../../config/constant";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { ThreeDots } from "react-loader-spinner";
import { getProductTypeName } from "../../../core/utils";

const Auction = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [countries, setcountries] = useState(
    JSON.parse(localStorage.getItem("countries"))
  );

  const [userAccess, _userAccess] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );

  let auctionAccess = userAccess.find((o) => o.slug === "auction_event");

  const [searchValue, _searchValue] = useState("");

  const [page, setPage] = useState(
    searchParams.get("auction_page") !== null
      ? parseInt(searchParams.get("auction_page"))
      : 1
  );
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [data, _data] = useState([]);
  const [loaded, _loaded] = useState(false);

  const { auctionId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getAuctionEvents();
  }, [page]);

  const getAuctionEvents = () => {
    API.get(APP_URLS.LIST_AUCTIONS + `/${auctionId}?page=${page}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loaded(true);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSwitch = (id) => {
    let newData = [...data];
    let val = newData.find((o) => o.id === id);
    if (val.active === 1) {
      val.active = 0;
    } else {
      val.active = 1;
    }
    _data(newData);
  };

  const disableAuction = (id, val) => {
    let data = {};
    data["active"] = val;
    API.post(
      APP_URLS.DISABLE_AUCTION + `/${id}/update_status?_method=patch`,
      data
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          toast.success(
            `Auction has been ${
              val === 1 ? "enabled" : "disabled"
            } successfully.`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          getAuctionEvents();
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <AuctionWrapper>
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <img
                src={back}
                className="ms-4 backbtn"
                onClick={() =>
                  navigate(
                    "/auction-events?" +
                      (searchParams.get("ae_country_id") !== null
                        ? "ae_country_id=" +
                          searchParams.get("ae_country_id") +
                          "&"
                        : "") +
                      (searchParams.get("ae_seller_allowed") !== null
                        ? "ae_seller_allowed=" +
                          searchParams.get("ae_seller_allowed") +
                          "&"
                        : "") +
                      (searchParams.get("ae_display_for") !== null
                        ? "ae_display_for=" +
                          searchParams.get("ae_display_for") +
                          "&"
                        : "") +
                      (searchParams.get("ae_product_type_id") !== null
                        ? "ae_product_type_id=" +
                          searchParams.get("ae_product_type_id") +
                          "&"
                        : "") +
                      (searchParams.get("ae_recurring") !== null
                        ? "ae_recurring=" +
                          searchParams.get("ae_recurring") +
                          "&"
                        : "") +
                      (searchParams.get("ae_keyword") !== null
                        ? "ae_keyword=" + searchParams.get("ae_keyword") + "&"
                        : "") +
                      (searchParams.get("ae_start_day") !== null
                        ? "ae_start_day=" +
                          searchParams.get("ae_start_day") +
                          "&"
                        : "") +
                      (searchParams.get("ae_end_day") !== null
                        ? "ae_end_day=" + searchParams.get("ae_end_day") + "&"
                        : "") +
                      (searchParams.get("ae_per_page") !== null
                        ? "ae_per_page=" + searchParams.get("ae_per_page") + "&"
                        : "") +
                      (searchParams.get("ae_page") !== null
                        ? "ae_page=" + searchParams.get("ae_page")
                        : "")
                  )
                }
              />
            </div>
            <div className="col-md-4 ">
              <h1>AUCTION</h1>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-3 me-2">
              <span className="text-start">
                Total Records : <b>{total}</b>
              </span>
            </div>
          </div>
          {loaded ? (
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th>Auction Title</th>
                    <th>Status</th>
                    <th>Product Type</th>
                    <th>
                      Start Time
                      <br /> End Time
                    </th>
                    <th>Country</th>
                    <th>Seller</th>
                    <th>Buyer</th>
                    <th>
                      Extension Period
                      <br />
                      <span className="fieldTitle">(S)</span>
                    </th>
                    <th>Number of Ads</th>
                    {auctionAccess.permissions.find(
                      (o) => o.slug === "delete"
                    ) ? (
                      <th>Action</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                      let flag = countries.find(
                        (o) => o.id === item.auction_event.country_id
                      );
                      return (
                        <tr className="table-row" key={index}>
                          <td>
                            <span title={item.auction_event.description["en"]}>
                              {item.auction_event.title["en"]}
                            </span>
                          </td>
                          <td>
                            <span
                              className={Constant.AUCTION_STATUS[item.status]}
                            >
                              {item.status
                                ? item.status
                                    .replace("_", " ")
                                    .charAt(0)
                                    .toUpperCase() +
                                  item.status.replace("_", " ").slice(1)
                                : "-"}
                            </span>
                          </td>
                          <td>
                            {item?.auction_event?.product_category_type &&
                            item?.auction_event?.product_category_type
                              ?.product_type_id
                              ? getProductTypeName(
                                  item.auction_event.product_category_type
                                    .product_type_id
                                )
                              : "-"}
                          </td>
                          <td>
                            <span className="d-block">
                              {moment(item.start)
                                .tz("Europe/Amsterdam")
                                .format("DD-MM-YYYY HH:mm")}
                            </span>
                            <span className="block">
                              {moment(item.end)
                                .tz("Europe/Amsterdam")
                                .format("DD-MM-YYYY HH:mm")}
                            </span>
                          </td>
                          <td>
                            <img
                              src={flag.flag_thumbnail}
                              alt=""
                              className="countryflag"
                              title={flag.fullname}
                            />
                          </td>
                          <td>
                            {
                              Constant.TYPE_OF_PROFILE[
                                item.auction_event.seller_allowed
                              ]
                            }
                          </td>
                          <td>
                            {
                              Constant.TYPE_OF_PROFILE[
                                item.auction_event.display_for
                              ]
                            }
                          </td>
                          <td>{item.auction_event.second_extension}</td>
                          <td>
                            <NavLink
                              to={
                                `/auction-ads/${item.id}?` +
                                (searchParams.get("ae_sort") !== null
                                  ? "ae_sort=" +
                                    searchParams.get("ae_sort") +
                                    "&"
                                  : "") +
                                (searchParams.get("ae_country_id") !== null
                                  ? "ae_country_id=" +
                                    searchParams.get("ae_country_id") +
                                    "&"
                                  : "") +
                                (searchParams.get("ae_seller_allowed") !== null
                                  ? "ae_seller_allowed=" +
                                    searchParams.get("ae_seller_allowed") +
                                    "&"
                                  : "") +
                                (searchParams.get("ae_display_for") !== null
                                  ? "ae_display_for=" +
                                    searchParams.get("ae_display_for") +
                                    "&"
                                  : "") +
                                (searchParams.get("ae_product_type_id") !== null
                                  ? "ae_product_type_id=" +
                                    searchParams.get("ae_product_type_id") +
                                    "&"
                                  : "") +
                                (searchParams.get("ae_recurring") !== null
                                  ? "ae_recurring=" +
                                    searchParams.get("ae_recurring") +
                                    "&"
                                  : "") +
                                (searchParams.get("ae_keyword") !== null
                                  ? "ae_keyword=" +
                                    searchParams.get("ae_keyword") +
                                    "&"
                                  : "") +
                                (searchParams.get("ae_start_day") !== null
                                  ? "ae_start_day=" +
                                    searchParams.get("ae_start_day") +
                                    "&"
                                  : "") +
                                (searchParams.get("ae_end_day") !== null
                                  ? "ae_end_day=" +
                                    searchParams.get("ae_end_day") +
                                    "&"
                                  : "") +
                                (searchParams.get("ae_per_page") !== null
                                  ? "ae_per_page=" +
                                    searchParams.get("ae_per_page") +
                                    "&"
                                  : "") +
                                (searchParams.get("ae_page") !== null
                                  ? "ae_page=" +
                                    searchParams.get("ae_page") +
                                    "&"
                                  : "") +
                                ("auction_id=" + auctionId + "&") +
                                (page !== 1 ? "auction_page=" + page + "&" : "")
                              }
                              className="link"
                            >
                              {item.ad_cars_count}
                            </NavLink>
                          </td>
                          {auctionAccess.permissions.find(
                            (o) => o.slug === "delete"
                          ) ? (
                            <td className="action">
                              <label className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={item.active}
                                  title={item.active ? "Disable" : "Enable"}
                                  onChange={() => {
                                    handleSwitch(item.id);
                                    disableAuction(item.id, item.active);
                                  }}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecord />
                  )}
                </tbody>
              </table>
              <div>
                {totalPage > 1 ? (
                  <Pagination
                    totalPages={totalPage}
                    page={page}
                    key={totalPage}
                    onPageClick={(page) => setPage(page + 1)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center three-dot">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </div>
      </AuctionWrapper>
    </>
  );
};

export default Auction;
