import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import save from "../../../assets/Images/icons/Save.svg";

const SearchableAutoCompleteWithChips = ({
  options,
  onChange,
  chips,
  disabled,
  func,
  keyword,
  getSelectedData,
  placeholder,
}) => {
  const [allOptions, setAllOptions] = useState(options);

  // Update options data on deleting chip
  useEffect(() => {
    getSelectedData();
  }, [chips]);

  // Update options when options prop changes
  useEffect(() => {
    setAllOptions([...options]);
  }, [options]);

  return (
    <div>
      <Autocomplete
        multiple
        value={chips}
        disableCloseOnSelect
        id="autocomplete"
        options={allOptions}
        noOptionsText={
          !keyword || keyword == "" ? "Start Seaching..." : "No options found"
        }
        getOptionLabel={(option) => option.name ?? ""} // Ensure we are showing label in the dropdown
        disabled={disabled}
        onChange={(event, value) => {
          onChange(value); // Return array of selected labels and their corresponding IDs
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(e, newValue) => {
          if (func) {
            func(newValue);
          }
        }}
        inputValue={keyword}
        renderTags={() => null} // This will prevent tags from being displayed
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder={placeholder ?? "Select the Dealer Name"}
          />
        )}
        filterOptions={(options, state) => options}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span>{option.name ?? option.email}</span>
              {props["aria-selected"] && <img src={save} alt="" />}
            </div>
          </li>
        )}
        sx={{
          ".MuiInput-input": {
            padding: "4px !important",
          },
          ".MuiAutocomplete-inputRoot": {
            paddingRight: "0px !important",
          },
        }}
      />
    </div>
  );
};

export default SearchableAutoCompleteWithChips;
