import styled from "styled-components";

export const CreateNotificationWrapper = styled.div`
  .input-fileds textarea {
    border: 1px solid #979797;
    border-radius: 4px;
  }
  .input-fileds img {
    width: 25px;
    height: 25px;
  }
  .headerContainer {
    justify-content: center !important;
  }
  select,
  textarea {
    border: 1px solid #ced4da !important;
    border-radius: 4px;
  }
  .add-button {
    white-space: nowrap;
    float: right;
    border: none !important;
    border-radius: 5px;
    background: #fbc524 !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 0 20px;
    height: 35px;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px !important;
  }
  .MuiAutocomplete-root {
    width: 100% !important;
  }
  .MuiAutocomplete-input {
    border: none !important;
    padding: 0px 0px 0px 5px !important;
  }
  .tooltipIcon {
    cursor: pointer;
    width: 13px;
    margin-left: 5px;
  }
`;
