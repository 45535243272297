import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { EditVehicleWrapper } from "../style/EditVehicle";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { getProductTypeName } from "../../../core/utils";

let name = {};
const EditVehicleMaterials = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { materialsId } = useParams();
  const [languages, _languages] = useState(
    JSON.parse(localStorage.getItem("languages"))
  );
  const [countries, _countries] = useState(
    JSON.parse(localStorage.getItem("countries"))
  );

  const [loading, _loading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getMaterials();
    let language = languages;
    let code = "";
    for (let i = 0; i < language.length; i++) {
      code = language[i].code;
      name[code] = "";
    }
  }, []);

  const setValues = (data) => {
    Object.keys(data).map((val, index) => {
      let elem = document.getElementById(val);
      if (elem !== null) {
        elem.value = data[val];
        name[val] = data[val];
      }
    });
  };

  const getMaterials = () => {
    API.get(APP_URLS.SHOW_CONSTANTS + `/${materialsId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setValues(resp.data.constant.name);
          document.getElementById("productType").value = getProductTypeName(
            resp.data.constant.product_type_id
          );
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.data !== undefined) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp?.data?.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp?.data?.error !== undefined) {
          error_message = resp?.data.error;
        } else {
          error_message = resp?.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleTitle = (val, code) => {
    name[code] = val;
  };

  const submitForm = () => {
    let flag = false;
    if (
      (name["en"] === "" || name["en"] === undefined || name["en"] === null) &&
      (name["fr"] === "" || name["fr"] === undefined || name["fr"] === null)
    ) {
      flag = false;
      toast.error("Material Title in English and French is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      name["en"] === "" ||
      name["en"] === undefined ||
      name["en"] === null
    ) {
      flag = false;
      toast.error("Please provide Material Name in English", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      name["fr"] === "" ||
      name["fr"] === undefined ||
      name["fr"] === null
    ) {
      flag = false;
      toast.error("Please provide Material Name in French", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      flag = true;
    }
    if (flag === true) {
      if (!loading) {
        _loading(true);
        API.post(APP_URLS.UPDATE_CONSTANTS + `/${materialsId}?_method=patch`, {
          name,
        })
          .then((res) => {
            _loading(false);
            const resp = res.data;
            if (resp.success === true) {
              toast.success("Materials Updated Successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate(
                "/translation/vehicle-materials?" +
                  (searchParams.get("product_type_id") !== null
                    ? "product_type_id=" +
                      searchParams.get("product_type_id") +
                      "&"
                    : "") +
                  (searchParams.get("name") !== null
                    ? "name=" + searchParams.get("name") + "&"
                    : "") +
                  (searchParams.get("per_page") !== null
                    ? "per_page=" + searchParams.get("per_page") + "&"
                    : "") +
                  (searchParams.get("page") !== null
                    ? "page=" + searchParams.get("page")
                    : "")
              );
            } else {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(function (error) {
            _loading(false);
            const resp = error.response;
            let error_message = "";
            if (resp.data.data !== undefined && resp.data.data !== null) {
              {
                Object.keys(resp.data.data).map(
                  (error, index) => (error_message = resp.data.data[error][0])
                );
              }
            } else if (resp.data.data?.error !== undefined) {
              error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
              error_message = resp.data.error;
            } else {
              error_message = resp.data.message;
            }
            toast.error(error_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    }
  };
  return (
    <>
      <EditVehicleWrapper>
        <div className="mt-2">
          <div
            className="backContainer ms-4"
            onClick={() =>
              navigate(
                "/translation/vehicle-materials?" +
                  (searchParams.get("product_type_id") !== null
                    ? "product_type_id=" +
                      searchParams.get("product_type_id") +
                      "&"
                    : "") +
                  (searchParams.get("name") !== null
                    ? "name=" + searchParams.get("name") + "&"
                    : "") +
                  (searchParams.get("per_page") !== null
                    ? "per_page=" + searchParams.get("per_page") + "&"
                    : "") +
                  (searchParams.get("page") !== null
                    ? "page=" + searchParams.get("page")
                    : "")
              )
            }
          >
            <span className="backButton">x</span>
            <span className="px-3 backButton">|</span>
            <span className="backButton">Cancel</span>
          </div>
        </div>
        <div className="headerContainer">
          <h1>EDIT MATERIALS</h1>
        </div>
        <div className="d-flex justify-content-between save-btn mt-3">
          <p className="fieldLabel">Title</p>
          <button
            className={(loading ? "disabled" : "") + " add-button"}
            onClick={submitForm}
            disabled={loading}
          >
            Save
          </button>
        </div>
        <div className="row input-fileds">
          {languages.map((item, index) => {
            let itemCode = item.code;
            let flag = countries.find(
              (o) => o.id === item.country_id
            )?.flag_thumbnail;
            return (
              <div className="col-md-4" key={index}>
                <div className="col-md-12 mt-3">
                  <label htmlFor="title" className="form-label">
                    <span className="countryIcon px-2">
                      <img
                        src={flag}
                        alt=""
                        className="countryflag"
                        title={item.name}
                      />
                    </span>
                    <span className="langName">{item.code}</span>
                  </label>
                  <input
                    type="text"
                    id={itemCode}
                    name={itemCode}
                    className="form-control departmentFields"
                    onChange={(e, code = itemCode) => {
                      handleTitle(e.target.value, code);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="row mt-5 input-fileds">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <label
                  htmlFor="productType"
                  className="form-label product-type d-flex justify-content-between fieldLabel"
                >
                  <span>Product Type</span>
                </label>
                <input
                  type="text"
                  name="productType"
                  id="productType"
                  className="form-control mt-3 disabled"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </EditVehicleWrapper>
    </>
  );
};

export default EditVehicleMaterials;
