import { useContext, useEffect } from "react";
import "./App.css";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchToken } from "./firebase/firebase";
import { MaintenanceContext } from "./context/MaintenanceContext";
import Maintenance from "./assets/SharedComponents/Maintenance/maintenance";
import { setMaintenanceHandler } from "./api/axios";
function App() {
  let authToken = localStorage.getItem("token");
  const permissions = localStorage.getItem("permissions");
  const user = localStorage.getItem("user");

  const { isMaintenance, setIsMaintenance } = useContext(MaintenanceContext);

  useEffect(() => {
    // Pass the maintenance handler to the Axios configuration
    setMaintenanceHandler(setIsMaintenance);
  }, [setIsMaintenance]);

  useEffect(() => {
    if (authToken && user && permissions) fetchToken();
  }, []);

  return (
    <div className="App">
      {isMaintenance && <Maintenance />}

      {!isMaintenance && (
        <>
          {authToken && user && permissions ? (
            <PrivateRoute />
          ) : (
            <PublicRoute />
          )}
          <ToastContainer />
        </>
      )}
    </div>
  );
}

export default App;
