import React from "react";
import { Chip } from "@mui/material";

const ChipList = ({ chips, onDelete, disabled }) => {
  return (
    <div>
      {chips?.map((value) => {
        return (
          <Chip
            key={`${value?.name ?? value?.email}-${value.id}`}
            disabled={disabled}
            label={value?.name ?? value?.email}
            onDelete={() => onDelete(value)}
            style={{ marginRight: 5, marginBottom: 5 }}
          />
        );
      })}
    </div>
  );
};

export default ChipList;
